import { Component, OnInit } from '@angular/core';
import { Menu, MenuService } from '../../services/menu/menu.service';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public menus = new Array<Menu>();

  constructor(private auth: AuthService, private menuService: MenuService, private router: Router) {
    this.menus = this.menuService.menus;
  }

  logout() {
    this.auth.logoutUser();
  }

  ngOnInit(): void {
    const paths = this.router.url.split('/');
    const selectedMenu = this.menuService.getMenuByUrl(paths[1]);
    if (selectedMenu) {
      this.menuService.setMenu(selectedMenu);
    }
  }

  setCurrentMenu(menu: Menu) {
    this.menuService.setMenu(menu);
  }
}
