import { Injectable } from '@angular/core';
import { ContactsData, Day, Filter, TableData } from '../../models/models';
import { AbstractFilterService } from './abstract-filter-service';
import { combineLatest, map, Observable, shareReplay } from 'rxjs';
import * as moment from 'moment';
import { SERVER_DATE_FORMAT } from '@shared/constants';
import { LoadingService } from '../loading.service';
import { PixelDayStore } from '../store/pixel/pixel-day-store.service';
import { PixelMappingStore } from '../store/pixel/pixel-mapping-store.service';
import { PixelAdTypeStore } from '../store/pixel/pixel-adType-store.service';
import { PixelNameStore } from '../store/pixel/pixel-name-store.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PanelStore } from '../store/panel-store.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsPixelFilterService extends AbstractFilterService {
  constructor(
    private pixelNameStore: PixelNameStore,
    private pixelDayStore: PixelDayStore,
    private pixelMappingStore: PixelMappingStore,
    private pixelAdTypeStore: PixelAdTypeStore,
    private loadingService: LoadingService,
    private panelStore: PanelStore,
    private http: HttpClient
  ) {
    super();
  }
  initRelation(): Observable<ContactsData> {
    const initialData$ = combineLatest([this.pixelNameStore.data, this.pixelDayStore.data, this.pixelMappingStore.data, this.pixelAdTypeStore.data, this.panelStore.data]).pipe(
      map(([customers, day, mappings, adtype, panels]) => {
        return {
          kunde: customers,
          mapping: mappings,
          adtype: adtype,
          day: {
            from_day: moment(day[0].from_day, SERVER_DATE_FORMAT),
            to_day: moment(day[0].to_day, SERVER_DATE_FORMAT)
          },
          panel: panels
        } as ContactsData;
      })
    );
    initialData$.subscribe((data) => {
      this.setInitialData(data);
    });
    return this.loadingService.showLoaderUntilCompleted(initialData$);
  }

  override getUnfilteredRelations(): ContactsData {
    return {
      mapping: this.initialData.mapping,
      kunde: this.initialData.kunde,
      adtype: this.initialData.adtype,
      panel: this.initialData.panel
    } as ContactsData;
  }

  filterChildrenServerSide(selectedFilter: Filter): Observable<ContactsData> {
    return combineLatest([this.pixelDayStore.getByParents(selectedFilter.kunde)]).pipe(
      map(
        ([day]) =>
          ({
            day: {
              from_day: moment(day[0].from_day, SERVER_DATE_FORMAT),
              to_day: moment(day[0].to_day, SERVER_DATE_FORMAT)
            } as Day
          } as ContactsData)
      )
    );
  }
  public getTableData(params: any): Observable<TableData> {
    return this.http.post<TableData>(environment.serverUrl + 'sehverhalten/pixel/werbekontakt/tabelle', params).pipe(shareReplay(1));
  }
}
