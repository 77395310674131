import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { LoginPage } from './pages/login/login.page';
import { AppLayoutComponent } from './components/layout/app-layout/app-layout.component';
import { TokenPage } from './pages/token/token.page';

const redirectUnauthorizedToLoginPage = () => redirectUnauthorizedTo(['/login']);

const routes: Routes = [
  { path: '', redirectTo: '/contacts/tv', pathMatch: 'full' },
  { path: 'login', component: LoginPage },
  { path: 'carsten-braucht-wieder-token', canActivate: [AngularFireAuthGuard], loadChildren: () => import('./pages/token/token.module').then((m) => m.TokenPageModule), component: TokenPage },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: 'contacts',
        loadChildren: () => import('./pages/contacts/contacts.module').then((m) => m.ContactsPageModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLoginPage }
      },
      {
        path: 'profiles',
        loadChildren: () => import('./pages/profiles/profiles.module').then((m) => m.ProfilesPageModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLoginPage }
      },
      {
        path: 'usage',
        loadChildren: () => import('./pages/usage/usage.module').then((m) => m.UsagePageModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLoginPage }
      },
      {
        path: 'segments',
        loadChildren: () => import('./pages/segments/segments.module').then((m) => m.SegmentsPageModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLoginPage }
      },
      {
        path: 'archive',
        loadChildren: () => import('./pages/archive/archive.module').then((m) => m.ArchivePageModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLoginPage }
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyPageModule)
      },
      {
        path: 'imprint',
        loadChildren: () => import('./pages/imprint/imprint.module').then((m) => m.ImprintPageModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
