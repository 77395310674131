import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { SharedModule } from '@shared/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FirebaseInterceptor } from './services/auth/firebase.interceptor';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { AgGridModule } from 'ag-grid-angular';
import { AppLayoutComponent } from './components/layout/app-layout/app-layout.component';
import { LoadingService } from './services/loading.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { GlobalErrorHandler } from './services/errors/global-error-handler';
import { FooterComponent } from './components/footer/footer.component';

registerLocaleData(localeDe);

@NgModule({
  declarations: [AppComponent, AppLayoutComponent, FooterComponent],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthGuardModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    AgGridModule,
    MatProgressSpinnerModule
  ],
  providers: [
    LoadingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FirebaseInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
