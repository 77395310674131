import { Injectable, OnDestroy } from '@angular/core';
import { ContactsData, Day, Filter, TableData } from '../../models/models';
import { AbstractFilterService } from './abstract-filter-service';
import { combineLatest, map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import * as moment from 'moment';
import { SERVER_DATE_FORMAT } from '@shared/constants';
import { LoadingService } from '../loading.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SendungenSenderStore } from '../store/sendungen/sendungen-sender-store.service';
import { SendungenSendungStore } from '../store/sendungen/sendungen-sendung-store.service';
import { SendungenDayStore } from '../store/sendungen/sendungen-day-store.service';
import { PanelStore } from '../store/panel-store.service';

@Injectable({
  providedIn: 'root'
})
export class UsageTvFilterService extends AbstractFilterService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private sendungenSenderStore: SendungenSenderStore,
    private sendungenSendungStore: SendungenSendungStore,
    private sendungenDayStore: SendungenDayStore,
    private panelStore: PanelStore,
    private loadingService: LoadingService,
    private http: HttpClient
  ) {
    super();
  }

  initRelation(): Observable<ContactsData> {
    const initialData$ = combineLatest([this.sendungenSenderStore.data, this.sendungenSendungStore.data, this.sendungenDayStore.data, this.panelStore.data]).pipe(
      takeUntil(this.destroy$),
      map(([sender, sendung, day, panels]) => {
        return {
          sender: sender,
          sendung: sendung,
          day: {
            from_day: moment(day[0].from_day, SERVER_DATE_FORMAT),
            to_day: moment(day[0].to_day, SERVER_DATE_FORMAT)
          },
          panel: panels
        } as ContactsData;
      })
    );
    initialData$.subscribe((data) => {
      this.setInitialData(data);
    });
    return this.loadingService.showLoaderUntilCompleted(initialData$);
  }

  filterChildrenServerSide(selectedFilter: Filter): Observable<ContactsData> {
    return combineLatest([this.sendungenDayStore.getByParents(selectedFilter.sender, selectedFilter.sendung)]).pipe(
      takeUntil(this.destroy$),
      map(
        ([day]) =>
          ({
            day: {
              from_day: day[0].from_day ? moment(day[0].from_day, SERVER_DATE_FORMAT) : null,
              to_day: day[0].to_day ? moment(day[0].to_day, SERVER_DATE_FORMAT) : null
            } as Day
          } as ContactsData)
      )
    );
  }

  public getTableData(params: any): Observable<TableData> {
    return this.http.post<TableData>(environment.serverUrl + 'sehverhalten/sendungen/nutzung/tabelle', params).pipe(shareReplay(1));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
