import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { filter, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { Menu, MenuService } from '../../../services/menu/menu.service';
import { MatSelectSearchComponent } from 'ngx-mat-select-search';
import { ContactsData, Filter, filterTypeRepo, Relation, SearchFilter, ValueOfSearchFilter } from '../../../models/models';
import { LoadingService } from '../../../services/loading.service';
import * as _ from 'lodash';
import { FilterFactoryService } from '../../../services/filter-layout/filter-factory.service';
import { AbstractFilterService } from '../../../services/filter-layout/abstract-filter-service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {
  @Input() mediaType: string | undefined;
  @Input() pageName: string | undefined;
  @Input() config: SearchFilter[] | undefined;
  @Input() clearFilterSubject: Subject<boolean> | undefined;
  @ViewChild('customerSearch') customerSearch!: MatSelectSearchComponent;
  searchFilter = SearchFilter;

  private destroy$ = new Subject<void>();
  private currentMenu: Menu | undefined;

  currentValue: string[] | undefined;
  private customersFilteredSubject = new ReplaySubject<string[]>();
  private productsFilteredSubject = new ReplaySubject<string[]>();
  customersFiltered$ = this.customersFilteredSubject.asObservable();
  productsFiltered$ = this.productsFilteredSubject.asObservable();
  currentSearchFilter: SearchFilter | undefined;
  isLoading: any;

  data: ContactsData | undefined;
  private filterService: AbstractFilterService;
  private filterRelations: { [P in ValueOfSearchFilter]?: Relation };
  private originalData: ContactsData | undefined;
  private isFirstTimeRelationLoaded = false;

  selectedFilter = {
    produkt: undefined,
    film: undefined,
    spot: undefined,
    lineItem: undefined,
    campaign: undefined,
    startDate: undefined,
    endDate: undefined,
    mapping: undefined,
    mediaType: undefined,
    mafo: undefined,

    kunde: undefined,
    kampagne: undefined,
    umfeld: undefined,
    gattung: undefined,
    sendung: undefined,
    sender: undefined,
    panel: undefined
  } as unknown as Filter;

  isRelationLoading = false;

  constructor(private menuService: MenuService, private contactsFilterFactoryService: FilterFactoryService, private loadingService: LoadingService, private router: Router) {}

  ngOnInit() {
    this.init();

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.init();
    });
  }

  private init() {
    this.menuService.currentMenu$.pipe(takeUntil(this.destroy$)).subscribe((menu) => {
      this.currentMenu = menu;
    });
    this.loadingService.loadingOn();
    this.filterService = this.contactsFilterFactoryService.getFilterService(this.mediaType, this.pageName);
    const filter = this.filterService.getFilter();
    if (filter) {
      this.selectedFilter = filter;
    }
    this.filterRelations = this.filterService.getFilterTypeRelations();
    this.filterService
      .initRelation()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (data) => {
        this.data = data;
        this.originalData = data;
        if (!this.selectedFilter.startDate) {
          this.selectedFilter.startDate = data.day?.from_day;
        }
        if (!this.selectedFilter.endDate) {
          this.selectedFilter.endDate = data.day?.to_day;
        }
        this.loadingService.loadingOff();
      });

    this.clearFilterSubject?.pipe(takeUntil(this.destroy$)).subscribe((resetFilter) => {
      if (resetFilter) {
        this.resetSelectedFilter();
        this.filterService.setFilter(undefined);
        this.data = _.cloneDeep(this.originalData);
      }
    });

    this.filterService
      .getFilter$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedFilter) => {
        if (selectedFilter) {
          this.selectedFilter = selectedFilter;
        }
      });
  }

  private resetSelectedFilter(): void {
    this.selectedFilter = {
      customer: undefined,
      produkt: undefined,
      film: undefined,
      spot: undefined,
      lineItem: undefined,
      campaign: undefined,
      siteAtv: undefined,
      siteDigital: undefined,
      mapping: undefined,
      mediaType: undefined,
      mafo: undefined,
      kunde: undefined,
      kampagne: undefined,
      gattung: undefined,
      sendung: undefined,
      sender: undefined
    } as unknown as Filter;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async onItemSelectDefault(searchFilter: SearchFilter | undefined, values: string[]) {
    if (!searchFilter) {
      throw new Error('searchFilter not set');
    }
    _.set(this.selectedFilter, searchFilter, values);
    this.currentSearchFilter = searchFilter;
    this.filterService.setFilter(this.selectedFilter);
    await this.doFilter(searchFilter, values);
  }

  async doFilter(searchFilter: SearchFilter, values: string[]) {
    if (!this.data) {
      return;
    }
    if (!this.isFirstTimeRelationLoaded) {
      this.loadingService.loadingOn();
    }

    this.data = await this.filterService.updateRelation(searchFilter, values, this.data[searchFilter], this.selectedFilter, this.isFirstTimeRelationLoaded, this.data);
    this.updateSelectedFilter(searchFilter, values);
    if (!this.isFirstTimeRelationLoaded) {
      this.loadingService.loadingOff();
      this.isFirstTimeRelationLoaded = true;
    }
    const currentFilterTypeRelation = this.filterRelations[searchFilter];
    if (currentFilterTypeRelation?.rootParent === null && !values.length) {
      this.isFirstTimeRelationLoaded = false;
    }
  }

  private updateSelectedFilter(searchFilter: SearchFilter, values: string[]) {
    const currentFilterTypeRelation = this.filterRelations[searchFilter];
    if (!currentFilterTypeRelation) {
      return;
    }
    this.updateParents(currentFilterTypeRelation.parents, searchFilter, values);
    this.updateChildren(currentFilterTypeRelation.children, searchFilter, values);
    this.updateOnDemandFields();
  }

  private updateParents(searchFilters: SearchFilter[], currentSearchFilter: SearchFilter, values: string[]) {
    for (const parent of searchFilters) {
      const parentFilterRelation = this.filterRelations[parent];
      if (parentFilterRelation?.rootParent === null && !this.selectedFilter[parent]?.length && this.data) {
        const key = filterTypeRepo[parent];
        const selectedParents = this.data[parent].filter((parent: any) => values.some((value) => this.containsOrEqualValue(value[key], parent[key])));
        _.set(this.selectedFilter, parent, selectedParents);
        continue;
      }
      // update all parents
      if (parentFilterRelation?.rootParent !== null && _.get(this.data, parent)?.length === 1) {
        _.set(this.selectedFilter, parent, _.get(this.data, parent));
      }
    }
  }

  private containsOrEqualValue(compareFrom: any, compareTo: any): boolean {
    if (Array.isArray(compareFrom)) {
      return compareFrom.includes(compareTo);
    }
    return compareFrom === compareTo;
  }

  private updateChildren(searchFilters: SearchFilter[], currentSearchFilter: SearchFilter, values: string[]) {
    for (const child of searchFilters) {
      if (!values.length) {
        _.set(this.selectedFilter, child, []);
        continue;
      }
      // update all children
      if (!this.isFirstTimeRelationLoaded && _.get(this.data, child)?.length === 1) {
        _.set(this.selectedFilter, child, _.get(this.data, child));
      } else {
        if (this.data && this.selectedFilter[child]?.length > this.data[child]?.length) {
          _.set(this.selectedFilter, child, _.get(this.data, child));
        } else {
          const key = filterTypeRepo[currentSearchFilter];
          const filteredItems = this.selectedFilter[child]?.filter((item: any) => values.some((value) => value[key] === item[key]));
          _.set(this.selectedFilter, child, filteredItems);
        }
      }
    }
  }

  //todo: make me dynamically
  private updateOnDemandFields() {
    if (this.data?.day) {
      this.selectedFilter.startDate = this.data.day.from_day;
      this.selectedFilter.endDate = this.data.day.to_day;
    }
    if (this.data?.umfeld?.length === 1) {
      this.selectedFilter.umfeld = this.data.umfeld;
    }
  }

  onItemSelectWithoutFilter(searchFilter: SearchFilter | undefined, values: any[]) {
    if (!searchFilter) {
      throw new Error('searchFilter must not be undefined');
    }
    this.selectedFilter[searchFilter] = values;
    this.filterService.setFilter(this.selectedFilter);
  }

  onDateChange() {
    this.filterService.setFilter(this.selectedFilter);
  }
}
