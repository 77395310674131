import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ArchiveService } from '../../../services/archive/archive.service';
import { Archive, ArchiveTypeEnum, CreateNode, EditModeEnum, EditNode, FileTypeEnum } from '../../../models/archive';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-archive-create-folder-dialog',
  templateUrl: './archive-create-folder-dialog.component.html',
  styleUrls: ['./archive-create-folder-dialog.component.scss']
})
export class ArchiveCreateFolderDialogComponent implements OnInit {
  currentItem: Archive;
  archiveType: ArchiveTypeEnum;
  editMode: EditModeEnum;

  editModeEnum = EditModeEnum;
  fileTypeEnum = FileTypeEnum;

  onSave = new EventEmitter<void>();

  form = new FormGroup({
    name: new FormControl('', [Validators.required])
  });

  constructor(private archiveService: ArchiveService, private dialogRef: MatDialogRef<ArchiveCreateFolderDialogComponent>) {}

  ngOnInit(): void {
    if (this.editMode === EditModeEnum.UPDATE) {
      this.form.get('name')?.setValue(this.currentItem?.name, { onlySelf: true });
    }
  }

  save() {
    const value = this.form.getRawValue();
    const newNode = {
      name: value.name,
      parent_id: this.currentItem ? this.currentItem.id : null,
      type: FileTypeEnum.FOLDER
    } as CreateNode;
    this.archiveService.create(this.archiveType, FileTypeEnum.FOLDER, newNode).subscribe(() => {
      this.onSave.emit();
      this.dialogRef.close();
    });
  }

  update() {
    const value = this.form.getRawValue();
    const newNode = {
      name: value.name,
      id: this.currentItem?.id,
      report_filter: this.currentItem?.report_filter
    } as EditNode;
    this.archiveService.update(this.archiveType, this.currentItem?.type, newNode).subscribe(() => {
      this.onSave.emit();
      this.dialogRef.close();
    });
  }

  copy() {
    const value = this.form.getRawValue();
    const newNode = {
      name: value.name,
      parent_id: this.currentItem.parent_id,
      type: FileTypeEnum.REPORT,
      report_filter: this.currentItem?.report_filter
    } as CreateNode;
    this.archiveService.create(this.archiveType, this.currentItem?.type, newNode).subscribe(() => {
      this.onSave.emit();
      this.dialogRef.close();
    });
  }
}
