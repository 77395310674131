<div class="d-flex align-items-center justify-content-between">
  <div class="table__count mt-3 mb-3" *ngIf="dataSource">
    {{
      dataSource?.data?.length === 1 ? ('table.countEntries.single' | translate: { number: dataSource?.data?.length }) : ('table.countEntries.plural' | translate: { number: dataSource?.data?.length })
    }}
    <span *ngIf="isFilterActive">{{ 'table.filtered' | translate }}</span>
  </div>
  <div>
    <button mat-button *ngIf="dataSource" class="auto" [disabled]="loadingService.loading$ | async" (click)="exportCSV()"><mat-icon>download</mat-icon>{{ 'button.csv' | translate }}</button>
    <button mat-button *ngIf="dataSource" class="auto" [disabled]="loadingService.loading$ | async" (click)="exportXLS()"><mat-icon>download</mat-icon>{{ 'button.xls' | translate }}</button>
  </div>
  <div *ngIf="isFilterActive">
    <button mat-raised-button class="action-button extra-small" (click)="onFilterReset()">{{ 'table.resetFilter' | translate }}</button>
  </div>
</div>
<div class="table" #TABLE>
  <table #table mat-table matSort [dataSource]="dataSource" [hidden]="!columns">
    <ng-container [matColumnDef]="column.field" *ngFor="let column of columns; let i = index">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.maxWidth.px]="column.width" [style.minWidth.px]="column.width">
        {{ column.name | translate }}
      </th>
      <td mat-cell *matCellDef="let element" [style.maxWidth.px]="column.width" [style.minWidth.px]="column.width" [title]="element[lodash.indexOf(dataScheme, column.field)]">
        <div *ngIf="!column.valueFormatter">
          {{ element[lodash.indexOf(dataScheme, column.field)] ? (element[lodash.indexOf(dataScheme, column.field)]) : '---'}}
        </div>
        <div *ngIf="column.valueFormatter">
          {{column.valueFormatter(element[lodash.indexOf(dataScheme, column.field)])}}
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnDefinitions"></tr>
    <tr mat-row *matRowDef="let row; columns: columnDefinitions" (click)="onCellClick(row)"></tr>
  </table>
</div>
<mat-paginator class="paginator" *ngIf="showPagination" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons aria-label="Seite"></mat-paginator>
