import { Component, OnDestroy, OnInit } from '@angular/core';
import { Archive, ArchiveTypeEnum } from '../../../models/archive';
import { ArchivePrivateStore } from '../../../services/store/archive/archive-private-store.service';
import { LoadingService } from '../../../services/loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-archive-private',
  templateUrl: './archive-private.page.html',
  styleUrls: ['./archive-private.page.scss']
})
export class ArchivePrivatePage implements OnInit, OnDestroy {
  public archiveData: Archive[];
  public archiveType = ArchiveTypeEnum;
  private dataSubscription: Subscription | undefined;

  constructor(private archivePrivateStore: ArchivePrivateStore, private loadingService: LoadingService) {}

  ngOnInit() {
    this.loadingService.loadingOn();
    this.archivePrivateStore.reloadData();
    this.dataSubscription = this.archivePrivateStore.data.subscribe((result: any) => {
      this.archiveData = result;
      this.loadingService.loadingOff();
    });
  }

  reloadData() {
    this.loadingService.loadingOn();
    this.archivePrivateStore.reloadData();
  }

  ngOnDestroy() {
    this.dataSubscription?.unsubscribe();
  }
}
