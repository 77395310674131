import { ColumnFilter, Relation, SearchFilter, ValueOfSearchFilter } from '../models/models';

export const filterTypeRelations: { [P in ValueOfSearchFilter]?: Relation } = {
  kunde: {
    rootParent: null,
    parents: [],
    children: [SearchFilter.KAMPAGNE, SearchFilter.LINE_ITEM, SearchFilter.PRODUCT, SearchFilter.FILM, SearchFilter.SPOT]
  },
  produkt: {
    rootParent: SearchFilter.KUNDE,
    parents: [SearchFilter.KUNDE],
    children: [SearchFilter.FILM, SearchFilter.SPOT]
  },
  film: {
    rootParent: SearchFilter.KUNDE,
    parents: [SearchFilter.PRODUCT, SearchFilter.KUNDE],
    children: [SearchFilter.SPOT]
  },
  spot: {
    rootParent: SearchFilter.KUNDE,
    parents: [SearchFilter.FILM, SearchFilter.PRODUCT, SearchFilter.KUNDE],
    children: []
  },
  kampagne: {
    rootParent: SearchFilter.KUNDE,
    parents: [SearchFilter.KUNDE],
    children: [SearchFilter.LINE_ITEM]
  },
  lineItem: {
    rootParent: SearchFilter.KUNDE,
    parents: [SearchFilter.KAMPAGNE, SearchFilter.KUNDE],
    children: []
  },
  sender: {
    rootParent: null,
    parents: [],
    children: [SearchFilter.SENDUNG]
  },
  sendung: {
    rootParent: SearchFilter.SENDER,
    parents: [SearchFilter.SENDER],
    children: []
  }
};
export const filterConfig: FilterConfig = {
  contacts: {
    tv: [SearchFilter.KUNDE, SearchFilter.PRODUCT, SearchFilter.FILM, SearchFilter.SPOT, SearchFilter.UMFELD, SearchFilter.START_DATE, SearchFilter.END_DATE, SearchFilter.PANEL],
    atv: [SearchFilter.KUNDE, SearchFilter.KAMPAGNE, SearchFilter.LINE_ITEM, SearchFilter.UMFELD, SearchFilter.START_DATE, SearchFilter.END_DATE, SearchFilter.PANEL],
    digital: [
      SearchFilter.KUNDE,
      SearchFilter.KAMPAGNE,
      SearchFilter.LINE_ITEM,
      SearchFilter.UMFELD,
      SearchFilter.START_DATE,
      SearchFilter.END_DATE,
      SearchFilter.GATTUNG,
      SearchFilter.MAPPING,
      SearchFilter.PANEL
    ],
    pixel: [SearchFilter.MAPPING, SearchFilter.KUNDE, SearchFilter.START_DATE, SearchFilter.END_DATE, SearchFilter.AD_TYPE, SearchFilter.PANEL],
    crossmedia: [SearchFilter.KUNDE, SearchFilter.KAMPAGNE, SearchFilter.LINE_ITEM, SearchFilter.START_DATE, SearchFilter.END_DATE, SearchFilter.GATTUNG, SearchFilter.MEDIA_TYPE, SearchFilter.PANEL],
    livestream: []
  },
  usage: {
    tv: [SearchFilter.SENDER, SearchFilter.SENDUNG, SearchFilter.START_DATE, SearchFilter.END_DATE, SearchFilter.PANEL],
    atv: [],
    digital: [SearchFilter.START_DATE, SearchFilter.END_DATE, SearchFilter.UMFELD, SearchFilter.PANEL],
    pixel: [],
    livestream: [SearchFilter.KUNDE, SearchFilter.START_DATE, SearchFilter.END_DATE, SearchFilter.GATTUNG, SearchFilter.PANEL],
    crossmedia: [SearchFilter.KUNDE, SearchFilter.KAMPAGNE, SearchFilter.LINE_ITEM, SearchFilter.START_DATE, SearchFilter.END_DATE, SearchFilter.GATTUNG, SearchFilter.MEDIA_TYPE, SearchFilter.PANEL]
  }
};

export const layoutConfig: LayoutConfig = {
  contacts: {
    tv: {
      compact: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.CONTACT],
      detail: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.UMFELD, ColumnFilter.SPOT, ColumnFilter.DAYTIME],
      compactAdditional: [ColumnFilter.AGE, ColumnFilter.GENDER, ColumnFilter.UMFELD, ColumnFilter.PRODUCT, ColumnFilter.FILM, ColumnFilter.SPOT],
      detailAdditional: [ColumnFilter.AGE, ColumnFilter.GENDER, ColumnFilter.KUNDE, ColumnFilter.PRODUCT, ColumnFilter.FILM]
    },
    atv: {
      compact: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.CONTACT],
      detail: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.DEVICE, ColumnFilter.UMFELD, ColumnFilter.DATE],
      compactAdditional: [ColumnFilter.AGE, ColumnFilter.GENDER, ColumnFilter.DEVICE, ColumnFilter.KUNDE, ColumnFilter.KAMPAGNE, ColumnFilter.LINE_ITEM],
      detailAdditional: [ColumnFilter.AGE, ColumnFilter.GENDER, ColumnFilter.KUNDE, ColumnFilter.KAMPAGNE, ColumnFilter.LINE_ITEM]
    },
    digital: {
      compact: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.CONTACT],
      detail: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.DEVICE, ColumnFilter.UMFELD, ColumnFilter.DATE, ColumnFilter.GATTUNG],
      compactAdditional: [ColumnFilter.AGE, ColumnFilter.GENDER, ColumnFilter.DEVICE, ColumnFilter.KUNDE, ColumnFilter.KAMPAGNE, ColumnFilter.LINE_ITEM, ColumnFilter.UMFELD, ColumnFilter.GATTUNG],
      detailAdditional: [ColumnFilter.AGE, ColumnFilter.GENDER, ColumnFilter.KUNDE, ColumnFilter.KAMPAGNE, ColumnFilter.LINE_ITEM, ColumnFilter.GATTUNG]
    },
    pixel: {
      compact: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.CONTACT],
      detail: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.DEVICE_PIXEL, ColumnFilter.DATE, ColumnFilter.AD_TYPE],
      compactAdditional: [ColumnFilter.AGE, ColumnFilter.GENDER, ColumnFilter.DEVICE_PIXEL, ColumnFilter.AD_TYPE],
      detailAdditional: [ColumnFilter.AGE, ColumnFilter.GENDER]
    },
    crossmedia: {
      compact: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.CONTACT],
      detail: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.DEVICE, ColumnFilter.UMFELD, ColumnFilter.DATE, ColumnFilter.GATTUNG, ColumnFilter.MEDIA_TYPE],
      compactAdditional: [],
      detailAdditional: []
    }
  },
  usage: {
    tv: {
      compact: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.CONTACT],
      detail: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.DATE],
      compactAdditional: [ColumnFilter.AGE, ColumnFilter.GENDER, ColumnFilter.SENDER, ColumnFilter.SENDUNG],
      detailAdditional: [ColumnFilter.AGE, ColumnFilter.GENDER, ColumnFilter.SENDER, ColumnFilter.SENDUNG]
    },
    atv: {
      compact: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.CONTACT],
      detail: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.DATE],
      compactAdditional: [],
      detailAdditional: []
    },
    digital: {
      compact: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.CONTACT],
      detail: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.DATE],
      compactAdditional: [ColumnFilter.AGE, ColumnFilter.DEVICE, ColumnFilter.GATTUNG, ColumnFilter.GENDER, ColumnFilter.UMFELD, ColumnFilter.ENVIRONMENT],
      detailAdditional: [ColumnFilter.AGE, ColumnFilter.DEVICE, ColumnFilter.GATTUNG, ColumnFilter.GENDER, ColumnFilter.UMFELD, ColumnFilter.ENVIRONMENT]
    },
    pixel: {
      compact: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.CONTACT],
      detail: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.DEVICE_PIXEL, ColumnFilter.DATE, ColumnFilter.AD_TYPE],
      compactAdditional: [],
      detailAdditional: []
    },
    crossmedia: {
      compact: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.PANELYST_ID, ColumnFilter.CONTACT],
      detail: [ColumnFilter.PANEL_ID, ColumnFilter.PANEL_NAME, ColumnFilter.DEVICE, ColumnFilter.UMFELD, ColumnFilter.DATE, ColumnFilter.GATTUNG, ColumnFilter.MEDIA_TYPE],
      compactAdditional: [],
      detailAdditional: []
    }
  }
};

export interface FilterConfig {
  contacts: FilterMediaType;
  usage?: FilterMediaType;
}

export interface FilterMediaType {
  atv: SearchFilter[];
  tv: SearchFilter[];
  digital: SearchFilter[];
  pixel: SearchFilter[];
  crossmedia: SearchFilter[];
  livestream: SearchFilter[];
}

export interface LayoutConfig {
  contacts: LayoutMediaTypeGroup;
  usage?: LayoutMediaTypeGroup;
}

export interface LayoutMediaType {
  compact: ColumnFilter[];
  detail: ColumnFilter[];
  compactAdditional: ColumnFilter[];
  detailAdditional: ColumnFilter[];
}

export interface LayoutMediaTypeGroup {
  atv: LayoutMediaType;
  tv: LayoutMediaType;
  digital: LayoutMediaType;
  pixel: LayoutMediaType;
  crossmedia: LayoutMediaType;
}
