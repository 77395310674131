import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, filter, Observable, Subject, takeUntil } from 'rxjs';
import { Menu, MenuService } from '../../services/menu/menu.service';
import { filterConfig, layoutConfig, LayoutMediaType } from '@shared/filter-layout-config';
import * as _ from 'lodash';
import { Column, Filter, SearchFilter } from '../../models/models';
import { FilterFactoryService } from '../../services/filter-layout/filter-factory.service';
import { AbstractFilterService } from '../../services/filter-layout/abstract-filter-service';
import { NavigationEnd, Router } from '@angular/router';

export enum ModeEnum {
  FILTER = 'FILTER',
  LAYOUT = 'LAYOUT'
}

@Component({
  selector: 'app-filter-layout-panel',
  templateUrl: './filter-layout-panel.component.html',
  styleUrls: ['./filter-layout-panel.component.scss']
})
export class FilterLayoutPanelComponent implements OnInit, OnDestroy {
  @Input() mediaType: string | undefined;
  @Input() pageName: string | undefined;
  @Output() filterChanged = new EventEmitter<{ filter: Observable<Filter>; columns: Observable<Column[]>; allColumns: Column[] }>();
  @Output() loadData = new EventEmitter<{ filter: Filter | undefined; selectedColumns: Column[]; allColumns: Column[] }>();
  @Output() filterToggled = new EventEmitter<boolean>();

  private currentMenu: Menu | undefined;
  private destroy$ = new Subject<void>();
  private filterService: AbstractFilterService;

  mode = ModeEnum;
  selectedMode = ModeEnum.FILTER;
  openFilter = true;
  filterConfig: SearchFilter[] | undefined;
  tableConfig: LayoutMediaType | undefined;
  resetFilterSubject = new Subject<boolean>();
  enableSearch = false;
  showResetSearch = false;

  constructor(private menuService: MenuService, private filterFactoryService: FilterFactoryService, private router: Router) {}

  ngOnInit(): void {
    this.init();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.init();
    });
  }

  private init() {
    this.filterService = this.filterFactoryService.getFilterService(this.mediaType, this.pageName);
    this.menuService.currentMenu$.pipe(takeUntil(this.destroy$)).subscribe((menu) => {
      this.currentMenu = menu;
      const config = _.get(filterConfig, menu.url, '');
      this.filterConfig = _.get(config, this.mediaType || '', '');

      const tableConfig = _.get(layoutConfig, menu.url, '');
      this.tableConfig = _.get(tableConfig, this.mediaType || '', '');
    });
    combineLatest([this.filterService.getFilter$(), this.filterService.getColumns$()])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([filter, columns]) => {
        if (!filter) {
          this.enableSearch = false;
          this.showResetSearch = false;
        } else {
          const currentFilter = _.pickBy(filter, (v) => (_.isArray(v) && v?.length) || (!_.isArray(v) && v !== undefined));
          this.enableSearch = !Object.keys(currentFilter).length ? false : true;
          this.showResetSearch = this.enableSearch;
        }
      });
  }

  toggle() {
    this.openFilter = !this.openFilter;
    this.filterToggled.emit(this.openFilter);
  }

  async search() {
    this.enableSearch = false;
    const service = this.filterFactoryService.getFilterService(this.mediaType, this.pageName);
    this.loadData.emit({
      filter: service.getFilter(),
      allColumns: service.getAllColumns(),
      selectedColumns: service.getColumns()
    });
  }

  changeMode(mode: ModeEnum) {
    this.selectedMode = mode;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onColumnChange(event: Column[]) {
    const service = this.filterFactoryService.getFilterService(this.mediaType, this.pageName);
    service.setColumns(event);
  }

  clear() {
    this.resetFilterSubject.next(true);
  }
}
