import { Injectable } from '@angular/core';
import { AbstractStoreService } from '../abstract-store.service';
import { HttpClient } from '@angular/common/http';
import { Day, Sender, Sendung } from '../../../models/models';
import { Observable, shareReplay } from 'rxjs';
import { environment } from '../../../../environments/environment';

const REST_ENDPOINT_URI = 'sehverhalten/sendungen/nutzung/day';

@Injectable({
  providedIn: 'root'
})
export class SendungenDayStore extends AbstractStoreService<Day> {
  constructor(http: HttpClient) {
    super(http, REST_ENDPOINT_URI);
  }

  getByParents(sender: Sender[] | undefined, sendung: Sendung[] | undefined): Observable<Day[]> {
    let urlParameters;
    if (sendung?.length) {
      urlParameters = '?sendung=' + encodeURIComponent(sendung.map((item) => item.sendung).join(','));
    } else if (sender?.length) {
      urlParameters = '?sender=' + encodeURIComponent(sender.map((item) => item.sender).join(','));
    } else {
      return this.data;
    }
    return this.http.get<Day[]>(environment.serverUrl + REST_ENDPOINT_URI + urlParameters).pipe(shareReplay());
  }
}
