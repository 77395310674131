import { Injectable, OnDestroy } from '@angular/core';
import { ContactsData, Day, Filter, TableData } from '../../models/models';
import { AbstractFilterService } from './abstract-filter-service';
import { combineLatest, map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import * as moment from 'moment';
import { SERVER_DATE_FORMAT } from '@shared/constants';
import { LoadingService } from '../loading.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PanelStore } from '../store/panel-store.service';
import { DigitalUsageSiteStore } from '../store/digital-usage/digital-usage-site-store.service';
import { DigitalUsageDayStore } from '../store/digital-usage/digital-usage-day-store.service';

@Injectable({
  providedIn: 'root'
})
export class UsageDigitalFilterService extends AbstractFilterService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private digitalUsageSiteStore: DigitalUsageSiteStore,
    private digitalUsageDayStore: DigitalUsageDayStore,
    private panelStore: PanelStore,
    private loadingService: LoadingService,
    private http: HttpClient
  ) {
    super();
  }

  initRelation(): Observable<ContactsData> {
    const initialData$ = combineLatest([this.digitalUsageSiteStore.data, this.digitalUsageDayStore.data, this.panelStore.data]).pipe(
      takeUntil(this.destroy$),
      map(([sites, day, panels]) => {
        return {
          umfeld: sites,
          day: {
            from_day: moment(day[0].from_day, SERVER_DATE_FORMAT),
            to_day: moment(day[0].to_day, SERVER_DATE_FORMAT)
          },
          panel: panels
        } as ContactsData;
      })
    );
    initialData$.subscribe((data) => {
      this.setInitialData(data);
    });
    return this.loadingService.showLoaderUntilCompleted(initialData$);
  }

  filterChildrenServerSide(selectedFilter: Filter): Observable<ContactsData> {
    return combineLatest([this.digitalUsageDayStore.getByParents(selectedFilter.umfeld)]).pipe(
      takeUntil(this.destroy$),
      map(
        ([day]) =>
          ({
            day: {
              from_day: day[0].from_day ? moment(day[0].from_day, SERVER_DATE_FORMAT) : null,
              to_day: day[0].to_day ? moment(day[0].to_day, SERVER_DATE_FORMAT) : null
            } as Day
          } as ContactsData)
      )
    );
  }

  public getTableData(params: any): Observable<TableData> {
    return this.http.post<TableData>(environment.serverUrl + 'sehverhalten/digital/nutzung/tabelle', params).pipe(shareReplay(1));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
