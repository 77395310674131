import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RtlGridExportService } from 'src/app/services/rtl-grid/export.service';
import _ from 'lodash';
import { LoadingService } from '../../services/loading.service';

const TABLE = {
  ITEMS_PER_PAGE: 'Einträge pro Seite: ',
  OF: 'von',
  PAGE: 'Seite'
};

export interface TableColumns {
  name: string;
  width?: number;
  field: string;
  valueFormatter?: any;
}

@Component({
  selector: 'app-rtl-grid',
  templateUrl: './rtl-grid.component.html',
  styleUrls: ['./rtl-grid.component.scss']
})
export class RtlGridComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() source: any[];
  @Input() columns: TableColumns[];
  @Input() dataScheme: string[];
  @Input() showPagination = true;
  @Input() isFilterActive = false;
  @Input() fileName: string | undefined;

  @Output() cellClicked: EventEmitter<any> = new EventEmitter();
  @Output() resetFilter: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('TABLE', { read: ElementRef }) table: ElementRef;

  public dataSource = new MatTableDataSource<any>();
  public columnDefinitions: string[];
  public lodash = _;

  constructor(private rtlGridExportService: RtlGridExportService, public loadingService: LoadingService) {}

  ngOnInit(): void {
    this.columnDefinitions = this.columns?.map((row: any) => row.field);
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource<any>(this.source);
    if (this.showPagination) {
      this.paginator._intl.itemsPerPageLabel = TABLE.ITEMS_PER_PAGE;
      this.paginator._intl.nextPageLabel = '';
      this.paginator._intl.lastPageLabel = '';
      this.paginator._intl.firstPageLabel = '';
      this.paginator._intl.previousPageLabel = '';
      this.paginator._intl.getRangeLabel = (page, pageSize, length) => {
        return `${TABLE.PAGE} ${page + 1} ${TABLE.OF} ${Math.floor(length / pageSize) + 1}`;
      };
      this.dataSource.paginator = this.paginator;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['source']?.currentValue) {
      this.dataSource.data = changes['source']?.currentValue;
    }
    if (changes['columns']?.currentValue) {
      this.columnDefinitions = changes['columns']?.currentValue?.map((row: any) => row.field);
    }
  }

  onCellClick(data: any) {
    this.cellClicked.emit(data);
  }

  onFilterReset() {
    this.resetFilter.emit();
  }

  exportCSV(): void {
    this.loadingService.loadingOn();
    this.rtlGridExportService.exportCSV(this.dataSource, this.columnDefinitions, this.dataScheme, this.fileName);
    this.loadingService.loadingOff();
  }

  exportXLS(): void {
    this.loadingService.loadingOn();
    this.rtlGridExportService.exportXLS(this.dataSource, this.columnDefinitions, this.dataScheme, this.fileName);
    this.loadingService.loadingOff();
  }
}
