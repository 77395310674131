import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/app/components/common/error-dialog/error-dialog.component';
import { LoadingService } from '../loading.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private dialog: MatDialog, private zone: NgZone, private loadingService: LoadingService) {}

  handleError(error: any) {
    if (error instanceof HttpErrorResponse) {
      const errorMessage = error.error;
      const errorDialog = this.dialog.open(ErrorDialogComponent, { width: '480px', hasBackdrop: true, disableClose: true });
      if (error.status >= 400 && error.status < 500) {
        this.zone.run(() => {
          //todo: soften error for 400's
          errorDialog.componentInstance.message = { key: errorMessage.error_code ? errorMessage.error_code : errorMessage.detail, error };
        });
      } else if (error.status >= 500) {
        this.zone.run(() => {
          errorDialog.componentInstance.message = { key: errorMessage.error_code ? errorMessage.error_code : errorMessage.detail, error };
        });
      }
      errorDialog.afterClosed().subscribe(() => this.dialog.closeAll());
    }
    console.error('Error from global error handler', error);
  }
}
