export enum StorageKeyEnum {
  AUTHENTICATION = 'authentication',
  LOGIN_IN_PROGRESS = 'loginInProgress'
}

export enum AuthenticationProviderEnum {
  RTL = 'oidc.netrtl.com',
  AD_ALLIANCE = 'oidc.ad-alliance.de'
}

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm:ss';
export const CLIENT_DATE_FORMAT = 'DD.MM.YYYY';
export const CLIENT_DATE_TIME_FORMAT = 'DD.MM.YYYY hh:mm:ss';
export const STAMMDATEN_COLUMN_AGE = 'age';
export const STAMMDATEN_COLUMN_GENDER = 'gender';
