<div class="filter-layout-panel expansion-panel-container" [ngClass]="openFilter ? 'opened' : 'closed'">
  <div>
    <button mat-button (click)="toggle()" class="toggle-button">
      <mat-icon *ngIf="openFilter">arrow_forward_ios_new</mat-icon>
      <mat-icon *ngIf="!openFilter">arrow_back_ios_new</mat-icon>
    </button>
  </div>

  <div class="expansion-panel-content">
    <div class="title-vertical" *ngIf="!openFilter">{{ 'slidePanel.title' | translate }}</div>

    <div class="full-width p-left-30" *ngIf="openFilter">
      <div class="panel-nav">
        <div class="nav-element" [class.active]="selectedMode === mode.FILTER" (click)="changeMode(mode.FILTER)">{{ 'slidePanel.searchCriteria' | translate }}</div>
        <div class="nav-element" [class.active]="selectedMode === mode.LAYOUT" (click)="changeMode(mode.LAYOUT)">{{ 'slidePanel.columns' | translate }}</div>
      </div>
      <div [hidden]="mode.FILTER !== selectedMode">
        <app-filter [config]="filterConfig" [mediaType]="mediaType" [pageName]="pageName" [clearFilterSubject]="resetFilterSubject"></app-filter>
      </div>
      <div [hidden]="mode.LAYOUT !== selectedMode">
        <app-table-config [config]="tableConfig" [mediaType]="mediaType" [pageName]="pageName" (columnsChanged)="onColumnChange($event)"></app-table-config>
      </div>
    </div>
  </div>

  <div class="expansion-panel-footer d-flex p-left-30 p-bottom-30 flex-column" *ngIf="openFilter">
    <button mat-raised-button class="action-button" (click)="search()" [disabled]="!enableSearch">{{ 'button.search' | translate }}</button>
    <div class="text-link p-top-16" (click)="clear()" *ngIf="showResetSearch && selectedMode === mode.FILTER">{{ 'button.searchReset' | translate }}</div>
  </div>
</div>
