<mat-dialog-content class="p-bottom-30 fs-m">
  <div class="text-center">
    <div class="p-top-30 fs-m2">
      <p>{{ 'error.' + message?.key | translate: message?.params }}</p>
      <code>{{ message?.error?.message }}</code>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="actions-container">
    <button mat-raised-button class="action-button small" [matDialogClose]="true">
      <span>{{ 'button.ok' | translate }}</span>
    </button>
  </div>
</mat-dialog-actions>
