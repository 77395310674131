<h2>{{ 'archive.createReport' | translate }}</h2>
<app-loading></app-loading>
<mat-dialog-content class="p-top-30">
  <form [formGroup]="form">
    <mat-label>{{ 'archive.reportName' | translate }}</mat-label> <br />
    <mat-form-field class="full-width fs-m" appearance="fill">
      <input matInput size="30" formControlName="name" autocomplete="off" placeholder="{{ 'archive.placeholder.reportName' | translate }}" />
    </mat-form-field>

    <mat-label>{{ 'archive.targetFolder' | translate }}</mat-label> <br />
    <div class="d-flex p-top-6">
      <button class="d-flex" (click)="openArchiveTreeDialog()" *ngIf="!selectedFolder">
        <mat-icon>search</mat-icon>
        {{ 'archive.selectFolder' | translate }}
      </button>
      <span *ngIf="selectedFolder" class="fs-m">{{ selectedFolder?.fullPath }}</span>
      <div class="text-link p-left-30" (click)="openArchiveTreeDialog()" *ngIf="selectedFolder">{{ 'archive.changeFolder' | translate }}</div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="actions-container">
    <button mat-raised-button class="action-button secondary auto" mat-dialog-close>{{ 'button.cancel' | translate }}</button>
    <button mat-raised-button class="action-button small" (click)="save()" [disabled]="form.invalid || !selectedFolder || !form.dirty">{{ 'button.create' | translate }}</button>
  </div>
</mat-dialog-actions>
