import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { TableColumns } from 'src/app/components/rtl-grid/rtl-grid.component';

@Injectable({
  providedIn: 'root'
})
export class RtlGridExportService {
  public lodash = _;

  exportCSV(dataSource: MatTableDataSource<any>, columnDefinitions: Array<string>, dataScheme?: Array<string>, fileName?: string): void {
    let csvData = this.convertToCSV(dataSource.data, columnDefinitions, dataScheme);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', (fileName ? fileName : `Export_${moment().format('YYYYMMDD_HHmm')}`) + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  exportXLS(table: MatTableDataSource<any>, columnDefinitions: Array<string>, dataScheme: Array<string>, fileName?: string) {
    const jsonData = this.convertToJson(table, columnDefinitions, dataScheme);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, (fileName ? fileName : `Export_${moment().format('YYYYMMDD_HHmm')}`) + '.xlsx');
  }

  private convertToJson(dataSource: MatTableDataSource<any>, columnDefinitions: Array<string>, dataScheme: Array<string>) {
    return dataSource.data.map((item) => {
      const obj = {};
      item.map((element: any, index: number) => {
        var key = dataScheme[index];
        obj[key] = element;
      });
      const objectSortedColumn = {};
      columnDefinitions.forEach((column) => {
        objectSortedColumn[column] = obj[column];
      });
      return objectSortedColumn;
    });
  }

  private convertToCSV(objArray: any, headerList: Array<string>, dataScheme?: Array<string>) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let j = 0; j < headerList.length; j++) {
        if (!dataScheme) {
          line += (array[i][j] ? array[i][j] : '') + ',';
        } else {
          line += (array[i][this.lodash.indexOf(dataScheme, headerList[j])] ? array[i][this.lodash.indexOf(dataScheme, headerList[j])] : '') + ',';
        }
      }
      line = line.slice(0, -1);
      str += line + '\r\n';
    }
    return str;
  }
}
