import { Injectable, Injector } from '@angular/core';
import { ContactsAtvFilterService } from './contacts-atv-filter.service';
import { ContactsTvFilterService } from './contacts-tv-filter.service';
import { ContactsDigitalFilterService } from './contacts-digital-filter.service';
import { AbstractFilterService } from './abstract-filter-service';
import { ContactsPixelFilterService } from './contacts-pixel-filter.service';
import { UsageTvFilterService } from './usage-tv-filter.service';
import { UsageDigitalFilterService } from './usage-digital-filter.service';

const PAGES = {
  usage: 'usage',
  contacts: 'contacts'
};
@Injectable({
  providedIn: 'root'
})
export class FilterFactoryService {
  constructor(private injector: Injector) {}

  getFilterService(mediaType: string | undefined, page: string | undefined): AbstractFilterService {
    switch (mediaType) {
      case 'tv':
        if (page === PAGES.usage) {
          return this.injector.get(UsageTvFilterService);
        }
        return this.injector.get(ContactsTvFilterService);
      case 'atv':
        return this.injector.get(ContactsAtvFilterService);
      case 'digital':
        if (page === PAGES.usage) {
          return this.injector.get(UsageDigitalFilterService);
        }
        return this.injector.get(ContactsDigitalFilterService);
      case 'pixel':
        return this.injector.get(ContactsPixelFilterService);
      default:
        throw new Error('Unsupported media type ' + mediaType);
    }
  }
}
