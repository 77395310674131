import { Component, OnInit } from '@angular/core';
import { ArchivePublicStore } from '../../../services/store/archive/archive-public-store.service';
import { Archive, ArchiveTypeEnum } from '../../../models/archive';
import { LoadingService } from '../../../services/loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-archive-public',
  templateUrl: './archive-public.page.html',
  styleUrls: ['./archive-public.page.scss']
})
export class ArchivePublicPage implements OnInit {
  public archiveData: Archive[];
  public archiveType = ArchiveTypeEnum;
  private dataSubscription: Subscription | undefined;

  constructor(private archivePublicStore: ArchivePublicStore, private loadingService: LoadingService) {}

  async ngOnInit() {
    this.loadingService.loadingOn();
    this.archivePublicStore.reloadData();
    this.dataSubscription = this.archivePublicStore.data.subscribe((result: any) => {
      this.archiveData = result;
      this.loadingService.loadingOff();
    });
  }

  reloadData() {
    this.loadingService.loadingOn();
    this.archivePublicStore.reloadData();
  }

  ngOnDestroy() {
    this.dataSubscription?.unsubscribe();
  }
}
