import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';
import { ArchiveCreateFolderDialogComponent } from '../archive-create-folder-dialog/archive-create-folder-dialog.component';
import { ComfirmationDialogComponent } from '../../common/comfirmation-dialog/comfirmation-dialog.component';
import { Archive, ArchiveTypeEnum, EditModeEnum, FileTypeEnum, ReportFilter } from '../../../models/archive';
import { ArchiveService } from '../../../services/archive/archive.service';
import _ from 'lodash';

@Component({
  selector: 'app-archive-tree',
  templateUrl: './archive-tree.component.html',
  styleUrls: ['./archive-tree.component.scss']
})
export class ArchiveTreeComponent implements OnInit, OnChanges {
  @Input() data!: Archive[];
  @Input() archiveType: ArchiveTypeEnum;
  @Output() reloadData = new EventEmitter();
  @Output() deleteData = new EventEmitter();

  public fileType = FileTypeEnum;
  public editMode = EditModeEnum;
  private currentItem: Archive | undefined;
  public expandedNodes = new Array<Archive>();
  private prevExpandedNodes = new Array<Archive>();
  private expandedNodesBeforeSearch = new Array<Archive>();
  public selectedNode: Archive | undefined;
  private flattedData = new Array<Archive>();

  public isSearching = false;
  treeControl = new NestedTreeControl<Archive>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<Archive>();
  searchText: string;
  dataCopy: Archive[];
  private readonly DIALOG_WIDTH = '900px';

  constructor(private dialog: MatDialog, private archiveService: ArchiveService) {}

  ngOnInit(): void {
    this.dataSource.data = this.data;
  }

  openCreateOrUpdateDialog(mode: EditModeEnum, item?: Archive) {
    this.prevExpandedNodes = this.getExpandedNodes();
    let matDialogRef = this.dialog.open(ArchiveCreateFolderDialogComponent, {
      width: this.DIALOG_WIDTH,
      hasBackdrop: true,
      disableClose: true
    });
    matDialogRef.componentInstance.archiveType = this.archiveType;
    if (item) {
      matDialogRef.componentInstance.currentItem = item;
    }
    matDialogRef.componentInstance.editMode = mode;

    matDialogRef.componentInstance.onSave.subscribe(() => {
      this.reloadData.emit();
      this.currentItem = item;
    });
  }

  deleteFolder(item: Archive) {
    this.prevExpandedNodes = this.treeControl.expansionModel.selected;
    let matDialogRef = this.dialog.open(ComfirmationDialogComponent, {
      width: this.DIALOG_WIDTH
    });
    matDialogRef.componentInstance.message =
      item.type === FileTypeEnum.FOLDER
        ? {
            key: 'archive.confirmation.delete.folder',
            params: { name: item.name }
          }
        : { key: 'archive.confirmation.delete.file', params: { name: item.name } };
    matDialogRef.componentInstance.onConfirm.subscribe(() => {
      this.archiveService.delete(this.archiveType, item).subscribe(() => {
        this.reloadData.emit();
      });
    });
  }

  expand(data: Archive[], id: string): any {
    for (const node of data) {
      if (node.id === id) {
        this.expandedNodes.push(node);
        return node;
      } else if (node.children?.length) {
        const result = this.expand(node.children, id);

        if (result) {
          this.expandedNodes.push(node);
          return result;
        }
      }
    }
  }

  expandSearch(data: Archive[], name: string): boolean {
    let found = false;
    for (const node of data) {
      node.show = false;
      if (node.children?.length) {
        const result = this.expandSearch(node.children, name);

        if (result) {
          const hasHiddenChild = node.children.some((child) => child.show);
          this.expandedNodes.push(node);
          node.show = hasHiddenChild;
          found = false;
          continue;
        }
      }
      if (node.name.toLowerCase().includes(name?.toLowerCase())) {
        node.show = true;
        this.expandedNodes.push(node);
        found = true;
      }
    }
    return found;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']?.currentValue) {
      this.flattedData = this.archiveService.toSingleLevelDeep(this.data);
      if (this.searchText) {
        this.search();
      } else {
        this.setTreeDataAndExpand();
        if (this.currentItem) {
          this.expand(this.data, this.currentItem.id);
          this.expandedNodes.forEach((n) => this.treeControl.expand(n));
        }
      }
    }
  }

  private setTreeDataAndExpand() {
    this.dataSource.data = this.data;
    this.treeControl.dataNodes = this.data;
    for (const expandedNodesModelElement of this.prevExpandedNodes) {
      this.expand(this.data, expandedNodesModelElement.id);
      this.expandedNodes.forEach((n) => this.treeControl.expand(n));
    }
  }

  private getExpandedNodes(): Archive[] {
    return this.treeControl.expansionModel.selected?.filter((item) => this.treeControl.isExpanded(item));
  }

  search() {
    if (!this.isSearching && this.searchText) {
      this.isSearching = true;
      this.expandedNodesBeforeSearch = this.getExpandedNodes();
    }
    if (this.searchText) {
      this.prevExpandedNodes = this.expandedNodes;
      this.expandedNodes = [];
      this.treeControl.collapseAll();
      this.dataCopy = _.cloneDeep(this.data);
      this.dataSource.data = this.dataCopy;
      this.treeControl.dataNodes = this.dataCopy;
      this.expandSearch(this.dataCopy, this.searchText);
      this.expandedNodes.forEach((n) => this.treeControl.expand(n));
    } else {
      this.isSearching = false;
      this.prevExpandedNodes = _.cloneDeep(this.expandedNodesBeforeSearch);
      this.setTreeDataAndExpand();
    }
  }

  clearSearchText() {
    this.isSearching = false;
    this.searchText = '';
    this.setTreeDataAndExpand();
  }

  selectNode(node: Archive) {
    const path = this.archiveService.getFullPath(this.flattedData, node);
    node.fullPath = path;
    this.selectedNode = node;
    this.archiveService.setSelectedArchive(node);
  }
}
