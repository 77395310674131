import { Injectable, OnDestroy } from '@angular/core';
import { ContactsData, Day, Filter, TableData } from '../../models/models';
import { AbstractFilterService } from './abstract-filter-service';
import { combineLatest, map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import * as moment from 'moment';
import { SERVER_DATE_FORMAT, SERVER_DATE_TIME_FORMAT } from '@shared/constants';
import { TvCustomerStore } from '../store/tv/tv-customer-store.service';
import { TvProductStore } from '../store/tv/tv-product-store.service';
import { TvFilmStore } from '../store/tv/tv-film-store.service';
import { TvSpotStore } from '../store/tv/tv-spot-store.service';
import { TvSiteStore } from '../store/tv/tv-site-store.service';
import { TvDayStore } from '../store/tv/tv-day-store.service';
import { LoadingService } from '../loading.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PanelStore } from '../store/panel-store.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsTvFilterService extends AbstractFilterService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private tvCustomerStore: TvCustomerStore,
    private tvProductStore: TvProductStore,
    private tvFilmStore: TvFilmStore,
    private tvSpotStore: TvSpotStore,
    private tvSiteStore: TvSiteStore,
    private tvDayStore: TvDayStore,
    private panelStore: PanelStore,
    private loadingService: LoadingService,
    private http: HttpClient
  ) {
    super();
  }

  initRelation(): Observable<ContactsData> {
    const initialData$ = combineLatest([
      this.tvCustomerStore.data,
      this.tvProductStore.data,
      this.tvFilmStore.data,
      this.tvSpotStore.data,
      this.tvSiteStore.data,
      this.tvDayStore.data,
      this.panelStore.data
    ]).pipe(
      takeUntil(this.destroy$),
      map(([customers, products, films, spots, sites, day, panels]) => {
        return {
          kunde: customers,
          produkt: products,
          film: films,
          spot: spots,
          umfeld: sites,
          day: {
            from_day: moment(day[0].from_day, SERVER_DATE_FORMAT),
            to_day: moment(day[0].to_day, SERVER_DATE_FORMAT)
          },
          panel: panels
        } as ContactsData;
      })
    );
    initialData$.subscribe((data) => {
      this.setInitialData(data);
    });
    return this.loadingService.showLoaderUntilCompleted(initialData$);
  }

  filterChildrenServerSide(selectedFilter: Filter): Observable<ContactsData> {
    return combineLatest([
      this.tvSiteStore.getByParents(selectedFilter.kunde, selectedFilter.produkt, selectedFilter.film, selectedFilter.spot),
      this.tvDayStore.getByParents(selectedFilter.kunde, selectedFilter.produkt, selectedFilter.film, selectedFilter.spot)
    ]).pipe(
      takeUntil(this.destroy$),
      map(
        ([sites, day]) =>
          ({
            umfeld: sites,
            day: {
              from_day: day[0].from_day ? moment(day[0].from_day, SERVER_DATE_FORMAT) : null,
              to_day: day[0].to_day ? moment(day[0].to_day, SERVER_DATE_FORMAT) : null
            } as Day
          } as ContactsData)
      )
    );
  }

  public getTableData(params: any): Observable<TableData> {
    return this.http.post<TableData>(environment.serverUrl + 'sehverhalten/spots/werbekontakt/tabelle', params).pipe(shareReplay(1));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
