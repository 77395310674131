import { Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-comfirmation-dialog',
  templateUrl: './comfirmation-dialog.component.html',
  styleUrls: ['./comfirmation-dialog.component.scss']
})
export class ComfirmationDialogComponent {
  public message: { key: string; params: any };
  public onConfirm = new EventEmitter();

  constructor() {}

  confirm() {
    this.onConfirm.emit();
  }
}
