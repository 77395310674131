import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { Datenstand } from 'src/app/models/models';
import { DatenstandStore } from './datenstandStore/datenstand-store.service';
@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  constructor(private datenstandStoreService: DatenstandStore) {}

  getDatenstand(): Observable<Datenstand[]> {
    return this.datenstandStoreService.data.pipe(shareReplay());
  }
}
