<div class="data-result-box-container">
  <div>
    <div class="result-value">
      <h2>{{ result | number }}</h2>
    </div>
    <div class="result-label">{{ 'searchResult.panelysts' | translate }}</div>
  </div>

  <div>
    <div class="result-value">
      <h2>{{ lastUpdate }}</h2>
    </div>
    <div class="result-label">{{ 'searchResult.dataStatus' | translate }}</div>
  </div>

  <div class="d-flex">
    <button mat-raised-button class="action-button save-archive" (click)="saveToArchive()">
      {{ 'searchResult.saveSearch' | translate }}
    </button>
  </div>
</div>
