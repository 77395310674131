import { BehaviorSubject, Observable } from 'rxjs';
import { Column, ContactsData, Filter, filterTypeRepo, Relation, SearchFilter, TableData } from '../../models/models';
import * as _ from 'lodash';
import { observableToPromise } from '../../utils/async-utils';
import { filterTypeRelations } from '@shared/filter-layout-config';
import { LayoutEnum } from '../../components/filter-layout-panel/table-config/table-config.component';
import { ReportFilter } from '../../models/archive';
import moment from 'moment';
import { CLIENT_DATE_FORMAT } from '@shared/constants';

export abstract class AbstractFilterService {
  protected initialData: ContactsData = {};

  private selectedFilterSubject = new BehaviorSubject<Filter | undefined>(undefined);
  private selectedColumnsSubject = new BehaviorSubject<Column[]>([]);
  private selectedLayoutSubject = new BehaviorSubject<LayoutEnum>(LayoutEnum.COMPACT);
  private tableDataSubject = new BehaviorSubject<TableData>({ schema: [], data: [], num_panelists: 0 });

  public selectedFilter$ = this.selectedFilterSubject.asObservable();
  public selectedColumns$ = this.selectedColumnsSubject.asObservable();
  public selectedLayout$ = this.selectedLayoutSubject.asObservable();
  private dataRequestPayload: any;
  private allColumns = new Array<Column>();

  setInitialData(data: ContactsData) {
    this.initialData = data;
  }

  abstract initRelation(): Observable<ContactsData>;

  getFilterTypeRelations(): any {
    return filterTypeRelations;
  }

  async updateRelation(
    currentFilter: SearchFilter,
    selectedItems: any[],
    selectOptions: any[],
    selectedFilter: Filter,
    relationLoaded: boolean,
    currentRelationData?: ContactsData
  ): Promise<ContactsData> {
    const repoKey: string | undefined = filterTypeRepo[currentFilter];
    if (!repoKey) {
      throw new Error('Unsupported repo key' + repoKey);
    }
    const filterTypeRelation = this.getFilterTypeRelations()[currentFilter];

    if (!filterTypeRelation) {
      return {};
    }
    let relationData = {};
    let childRelationData = {};

    if (!relationLoaded) {
      relationData = await this.loadRelationForFirstTime(filterTypeRelation, repoKey, selectedItems, selectedFilter, currentFilter);
    } else if (filterTypeRelation.rootParent === null) {
      childRelationData = await this.rootParentChanged(filterTypeRelation.children, repoKey, selectedItems, selectedFilter, filterTypeRelation, currentFilter, currentRelationData);
      const additionalRelations = await observableToPromise(this.filterChildrenServerSide(selectedFilter));
      const unfilteredRelations = this.getUnfilteredRelations();
      relationData = { ...childRelationData, ...additionalRelations, ...unfilteredRelations };
    } else {
      childRelationData = await this.filterChildren(filterTypeRelation.children, repoKey, selectedItems, selectedFilter, filterTypeRelation, currentFilter, currentRelationData);
      const additionalRelations = await observableToPromise(this.filterChildrenServerSide(selectedFilter));
      const unfilteredRelations = this.getUnfilteredRelations();
      relationData = { ...childRelationData, ...additionalRelations, ...unfilteredRelations };
    }

    if (filterTypeRelation.rootParent === null) {
      relationData[currentFilter] = this.initialData[currentFilter];
    }
    return relationData;
  }

  private async loadRelationForFirstTime(relation: Relation, repoKey: string, values: any[], selectedFilter: Filter, currentFilter: SearchFilter) {
    const dataParents = await this.filterParents(relation.parents, repoKey, values, selectedFilter, relation, currentFilter);
    const dataCurrent = await this.filterCurrent(currentFilter, values, dataParents);
    const dataChildren = await this.filterChildren(relation.children, repoKey, values, selectedFilter, relation, currentFilter);
    const additionalData = await observableToPromise(this.filterChildrenServerSide(selectedFilter));
    const unfilteredRelations = this.getUnfilteredRelations();
    return { ...dataParents, ...dataChildren, ...dataCurrent, ...additionalData, ...unfilteredRelations };
  }

  abstract filterChildrenServerSide(selectedFilter: Filter): Observable<ContactsData>;

  getUnfilteredRelations(): ContactsData {
    return {
      panel: this.initialData.panel
    } as ContactsData;
  }

  private async filterCurrent(currentFilter: SearchFilter, values: any[], dataParents: ContactsData) {
    let data = {};
    const relation = this.getFilterTypeRelations()[currentFilter];
    if (!relation || relation.rootParent === null) {
      return data;
    }
    const parentKey = filterTypeRepo[relation.parents[0]];
    const dataByProperty = this.initialData[currentFilter].filter((item: any) => {
      return values.some((value) => this.containsOrEqualValue(value[parentKey], item[parentKey]));
    });
    _.set(data, currentFilter, dataByProperty);

    return data;
  }

  async rootParentChanged(
    filterKeys: SearchFilter[],
    repoKey: string,
    values: any[],
    selectedFilter: Filter,
    relation: Relation,
    currentFilter: SearchFilter,
    currentRelationData?: ContactsData
  ): Promise<ContactsData> {
    let data = currentRelationData || ({} as ContactsData);
    if (!values.length) {
      for (const filterKey of filterKeys) {
        let dataByProperty = this.initialData[filterKey];
        _.set(data, filterKey, dataByProperty);
      }
      return data;
    }
    //update the first child only
    let dataByProperty;
    let lastChild: SearchFilter | undefined = undefined;
    for (const filterKey of filterKeys) {
      if (lastChild) {
        continue;
      }
      if (this.initialData[filterKey] && !lastChild) {
        dataByProperty = this.initialData[filterKey];
        if (!dataByProperty) {
          continue;
        }
        dataByProperty = dataByProperty.filter((item: any) => {
          const valueArray = _.map(values, repoKey);
          return this.containsOrEqualValue(valueArray, item[repoKey]);
        });
        _.set(data, filterKey, dataByProperty);
        lastChild = filterKey;
        continue;
      }
    }
    return data;
  }

  async filterChildren(
    filterKeys: SearchFilter[],
    repoKey: string,
    values: any[],
    selectedFilter: Filter,
    relation: Relation,
    currentFilter: SearchFilter,
    currentRelationData?: ContactsData
  ): Promise<ContactsData> {
    let data = currentRelationData || ({} as ContactsData);
    for (const filterKey of filterKeys) {
      let dataByProperty = this.initialData[filterKey];

      if (!values.length) {
        dataByProperty = dataByProperty.filter((value: any) => {
          return data[currentFilter].some((parent: any) => parent[repoKey] === value[repoKey]);
        });
        _.set(data, filterKey, dataByProperty);
        continue;
      }
      if (dataByProperty) {
        // last child filter is selected
        if (relation.children.length === 0) {
          let key = this.getFilterTypeRelations()[relation.parents[0]] || '';
          dataByProperty = dataByProperty.filter((item: any) => {
            return item[key] === values[0]?.[key];
          });
          _.set(data, filterKey, dataByProperty);
          continue;
        }
        dataByProperty = dataByProperty.filter((item: any) => {
          const valueArray = _.map(values, repoKey);
          return this.containsOrEqualValue(valueArray, item[repoKey]);
        });
      }
      _.set(data, filterKey, dataByProperty);
    }
    return data;
  }

  private containsOrEqualValue(compareFrom: any[], compareTo: any | any[]): boolean {
    if (Array.isArray(compareTo)) {
      return compareFrom.some((elementFromArrayA) => compareTo.includes(elementFromArrayA));
    }
    return compareFrom.includes(compareTo);
  }

  async filterParents(
    filterKeys: SearchFilter[],
    repoKey: string,
    values: any[],
    selectedFilter: Filter,
    relation: Relation,
    currentFilter: SearchFilter,
    currentRelationData?: ContactsData
  ): Promise<ContactsData> {
    let data = currentRelationData || ({} as ContactsData);
    for (const filterKey of filterKeys) {
      if (relation.rootParent === filterKey) {
        _.set(data, filterKey, this.initialData[filterKey]);
        continue;
      }
      let dataByProperty = this.initialData[filterKey];
      if (dataByProperty) {
        let key = this.getFilterTypeRelations()[filterKey] || '';
        dataByProperty = dataByProperty.filter((item: any) => {
          const valueArray = _.map(values, filterKey);
          return this.containsOrEqualValue(valueArray, item[filterKey]);
        });
        _.set(data, filterKey, dataByProperty);
      }
    }
    return data;
  }

  public async convertToFilter(reportFilter: ReportFilter): Promise<Filter> {
    if (_.isEmpty(this.initialData)) {
      const data = await observableToPromise(this.initRelation());
      this.setInitialData(data);
      return this.convert(reportFilter);
    }
    return this.convert(reportFilter);
  }

  private convert(reportFilter: ReportFilter): Filter {
    return {
      kunde: this.initialData.kunde?.filter((item) => reportFilter.filter.kunde?.some((kunde) => item.kunde === kunde)),
      produkt: this.initialData.produkt?.filter((item) => reportFilter.filter.produkt?.some((produkt) => item.produkt === produkt)),
      film: this.initialData.film?.filter((item) => reportFilter.filter.film?.some((film) => item.film === film)),
      spot: this.initialData.spot?.filter((item) => reportFilter.filter.werbespotname?.some((werbespotname) => item.werbespotname === werbespotname)),
      kampagne: this.initialData.kampagne?.filter((item) => reportFilter.filter.kampagne?.some((kampagne) => item.kampagne === kampagne)),
      lineItem: this.initialData.lineItem?.filter(
        (item) => reportFilter.filter.lineitem?.some((lineItem) => item.lineitem === lineItem) && reportFilter.filter.kunde.some((kunde) => item.kunde === kunde)
      ),
      umfeld: this.initialData.umfeld?.filter((item) => reportFilter.filter.umfeld?.some((umfeld) => item.umfeld === umfeld)),
      startDate: moment(reportFilter.filter.from_day, CLIENT_DATE_FORMAT),
      endDate: moment(reportFilter.filter.to_day, CLIENT_DATE_FORMAT),
      mapping: this.initialData.mapping?.filter((item) => reportFilter.filter.mapping?.some((mapping) => item.mapping === mapping)),
      gattung: this.initialData.gattung?.filter((item) => reportFilter.filter.gattung?.some((gattung) => item.gattung === gattung)),
      adtype: this.initialData.adtype?.filter((item) => reportFilter.filter.adtype?.some((adtype) => item.adtype === adtype)),
      sender: this.initialData.sender?.filter((item) => reportFilter.filter.sender?.some((sender) => item.sender === sender)),
      sendung: this.initialData.sendung?.filter((item) => reportFilter.filter.sendung?.some((sendung) => item.sendung === sendung)),
      panel: this.initialData.panel?.filter((item) => reportFilter.filter.panel?.some((panel) => item.panel === panel))
    } as Filter;
  }

  setFilter(filter: Filter | undefined): void {
    this.selectedFilterSubject.next(filter);
  }

  setColumns(columns: Column[]): void {
    this.selectedColumnsSubject.next(columns);
  }

  public getFilter$(): Observable<Filter | undefined> {
    return this.selectedFilter$;
  }

  public getFilter(): Filter | undefined {
    return this.selectedFilterSubject.getValue();
  }

  public getColumns$(): Observable<Column[]> {
    return this.selectedColumns$;
  }

  public getColumns(): Column[] {
    return this.selectedColumnsSubject.getValue();
  }

  public setSelectedLayout(layout: LayoutEnum) {
    this.selectedLayoutSubject.next(layout);
  }

  public getSelectedLayout(): LayoutEnum {
    return this.selectedLayoutSubject.getValue();
  }

  public getDataRequestPayload(): any {
    return this.dataRequestPayload;
  }

  public setDataRequestPayload(value: any) {
    this.dataRequestPayload = value;
  }

  public setAllColumns(columns: Column[]) {
    this.allColumns = columns;
  }

  public getAllColumns(): Column[] {
    return this.allColumns;
  }

  public setTableData(data: TableData) {
    this.tableDataSubject.next(data);
  }

  public getCachedTableData(): TableData {
    return this.tableDataSubject.getValue();
  }

  public abstract getTableData(params: any): Observable<TableData>;
}
