import { Component, EventEmitter, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Output() clickOutside = new EventEmitter<MouseEvent>();

  public email: string;
  public getSendFeedbackHref: string;
  public version = environment.version;

  constructor(private fireAuth: AngularFireAuth) {
    fireAuth.authState.subscribe((user) => {
      this.email = user?.email || '';
      this.getSendFeedbackHref = `mailto:${environment.getProductEmail}?subject=[Panelytics][Userfeedback]${user?.email}`;
    });
  }
}
