<div class="tree-container">
  <div class="d-flex p-bottom-30">
    <div class="d-flex align-items-center cursor-pointer w-100" (click)="openCreateOrUpdateDialog(editMode.CREATE)">
      <mat-icon class="add-root-icon">add</mat-icon>
      <span>{{ 'archive.addRootFolder' | translate }}</span>
    </div>

    <mat-form-field class="full-width with-icon" appearance="fill">
      <mat-icon matPrefix class="search-icon">search</mat-icon>
      <input matInput [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="Ordner durchsuchen" autocomplete="off"  [disabled]="!dataSource?.data?.length"/>
      <mat-icon matSuffix class="search-icon cursor-pointer" *ngIf="searchText" (click)="clearSearchText()">clear</mat-icon>
    </mat-form-field>
  </div>

  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-nested-tree-node *matTreeNodeDef="let node">
      <li *ngIf="node.show === undefined || node.show">
        <div class="mat-tree-node" [class.selected]="node.id === selectedNode?.id">
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" [disabled]="!node?.children?.length">
            <mat-icon class="mat-icon-rtl-mirror action-icon" matTreeNodeToggle *ngIf="node?.children?.length">
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          <div class="tree-node d-flex" *ngIf="node.show === undefined || node.show">
            <div class="node-name" matTreeNodeToggle (click)="selectNode(node)">
              <mat-icon class="folder-icon" *ngIf="node.type === fileType.FOLDER">folder</mat-icon>
              <mat-icon class="material-icons-outlined" *ngIf="node.type === fileType.REPORT">description</mat-icon>
              <span class="p-left-6 text-wrap-one-line" [title]="node.name">{{ node.name }}</span>
            </div>
            <div class="node-icons-hover">
              <button mat-icon-button (click)="openCreateOrUpdateDialog(editMode.CREATE, node)" *ngIf="node.type === fileType.FOLDER">
                <mat-icon class="material-icons-outlined action-icon">add</mat-icon>
              </button>
              <button mat-icon-button (click)="openCreateOrUpdateDialog(editMode.UPDATE, node)">
                <mat-icon class="material-icons-outlined action-icon">edit</mat-icon>
              </button>
              <button mat-icon-button (click)="openCreateOrUpdateDialog(editMode.COPY, node)" *ngIf="node.type === fileType.REPORT">
                <mat-icon class="material-icons-outlined action-icon">file_copy</mat-icon>
              </button>
              <button mat-icon-button (click)="deleteFolder(node)" *ngIf="!node.children?.length">
                <mat-icon class="material-icons-outlined action-icon">delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node) || (treeControl.isExpanded(node) && !node.children?.length)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>

  <div *ngIf="isSearching && !expandedNodes.length">
    {{ 'archive.emptyResult' | translate }}
  </div>
</div>
