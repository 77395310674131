import { Injectable } from '@angular/core';
import { AbstractStoreService } from '../abstract-store.service';
import { HttpClient } from '@angular/common/http';
import { Campaign } from '../../../models/models';

const REST_ENDPOINT_URI = 'sehverhalten/digital/werbekontakt/kampagne';

@Injectable({
  providedIn: 'root'
})
export class DigitalCampaignStore extends AbstractStoreService<Campaign> {
  constructor(http: HttpClient) {
    super(http, REST_ENDPOINT_URI);
  }
}
