import { Injectable } from '@angular/core';
import { ContactsData, Day, Filter, SearchFilter, TableData } from '../../models/models';
import { AbstractFilterService } from './abstract-filter-service';
import { combineLatest, map, Observable, shareReplay } from 'rxjs';
import * as moment from 'moment';
import { SERVER_DATE_FORMAT } from '@shared/constants';
import { LoadingService } from '../loading.service';
import { DigitalCustomerStore } from '../store/digital/digital-customer-store.service';
import { DigitalCampaignStore } from '../store/digital/digital-campaign-store.service';
import { DigitalLineItemStore } from '../store/digital/digital-lineitem-store.service';
import { DigitalSiteStore } from '../store/digital/digital-site-store.service';
import { DigitalDayStore } from '../store/digital/digital-day-store.service';
import { DigitalMappingnStore } from '../store/digital/digital-mapping-store.service';
import { DigitalGattungStore } from '../store/digital/digital-gattung-store.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PanelStore } from '../store/panel-store.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsDigitalFilterService extends AbstractFilterService {
  constructor(
    private digitalCustomerStore: DigitalCustomerStore,
    private digitalCampaignStore: DigitalCampaignStore,
    private digitalLineItemStore: DigitalLineItemStore,
    private digitalSiteStore: DigitalSiteStore,
    private digitalDayStore: DigitalDayStore,
    private digitalMappingStore: DigitalMappingnStore,
    private digitalGattungStore: DigitalGattungStore,
    private panelStore: PanelStore,
    private loadingService: LoadingService,
    private http: HttpClient
  ) {
    super();
  }

  initRelation(): Observable<ContactsData> {
    const initialData$ = combineLatest([
      this.digitalCustomerStore.data,
      this.digitalCampaignStore.data,
      this.digitalLineItemStore.data,
      this.digitalSiteStore.data,
      this.digitalDayStore.data,
      this.digitalMappingStore.data,
      this.digitalGattungStore.data,
      this.panelStore.data
    ]).pipe(
      map(([customers, campaigns, lineItems, sites, day, mappings, gattung, panels]) => {
        return {
          kunde: customers,
          kampagne: campaigns,
          lineItem: lineItems,
          umfeld: sites,
          mapping: mappings,
          gattung: gattung,
          day: {
            from_day: moment(day[0].from_day, SERVER_DATE_FORMAT),
            to_day: moment(day[0].to_day, SERVER_DATE_FORMAT)
          },
          panel: panels
        } as ContactsData;
      })
    );
    initialData$.subscribe((data) => {
      this.setInitialData(data);
    });
    return this.loadingService.showLoaderUntilCompleted(initialData$);
  }

  filterChildrenServerSide(selectedFilter: Filter): Observable<ContactsData> {
    return combineLatest([
      this.digitalSiteStore.getByParents(selectedFilter.kunde, selectedFilter.kampagne, selectedFilter.lineItem),
      this.digitalDayStore.getByParents(selectedFilter.kunde, selectedFilter.kampagne, selectedFilter.lineItem)
    ]).pipe(
      map(
        ([sites, day]) =>
          ({
            umfeld: sites,
            day: {
              from_day: moment(day[0].from_day, SERVER_DATE_FORMAT),
              to_day: moment(day[0].to_day, SERVER_DATE_FORMAT)
            } as Day
          } as ContactsData)
      )
    );
  }

  override getUnfilteredRelations(): ContactsData {
    return {
      mapping: this.initialData.mapping,
      mediaType: this.initialData.mediaType,
      panel: this.initialData.panel
    } as ContactsData;
  }

  public getTableData(params: any): Observable<TableData> {
    return this.http.post<TableData>(environment.serverUrl + 'sehverhalten/digital/werbekontakt/tabelle', params).pipe(shareReplay(1));
  }

  public override getFilterTypeRelations(): any {
    return {
      gattung: {
        rootParent: null,
        parents: [],
        children: [SearchFilter.KUNDE, SearchFilter.KAMPAGNE, SearchFilter.LINE_ITEM, SearchFilter.PRODUCT, SearchFilter.FILM, SearchFilter.SPOT]
      },
      kunde: {
        rootParent: SearchFilter.GATTUNG,
        parents: [SearchFilter.GATTUNG],
        children: [SearchFilter.KAMPAGNE, SearchFilter.LINE_ITEM, SearchFilter.PRODUCT, SearchFilter.FILM, SearchFilter.SPOT]
      },
      kampagne: {
        rootParent: SearchFilter.GATTUNG,
        parents: [SearchFilter.KUNDE, SearchFilter.GATTUNG],
        children: [SearchFilter.LINE_ITEM]
      },
      lineItem: {
        rootParent: SearchFilter.GATTUNG,
        parents: [SearchFilter.KAMPAGNE, SearchFilter.KUNDE, SearchFilter.GATTUNG],
        children: []
      }
    };
  }
}
