import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Datenstand } from 'src/app/models/models';
import { AbstractStoreService } from '../../abstract-store.service';

const REST_ENDPOINT_URI = 'sehverhalten/datenstand';

@Injectable({
  providedIn: 'root'
})
export class DatenstandStore extends AbstractStoreService<Datenstand> {
  constructor(http: HttpClient) {
    super(http, REST_ENDPOINT_URI);
  }
}
