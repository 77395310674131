<div class="p-left-30" *ngIf="selectedArchive">
  <h3>{{ selectedArchive.name }}</h3>
  <div class="p-top-16 p-bottom-0">
    <strong>{{ 'archive.author' | translate }}</strong> {{ selectedArchive.created_by_user_email }}
  </div>
  <div class="p-top-0 p-bottom-24">
    <strong>{{ 'archive.createdAt' | translate }}</strong> {{ selectedArchive.created_at | date }}
  </div>
  <div class="p-bottom-24">
    <div *ngIf="selectedArchive.report_filter?.filter">
      <h3>{{ 'archive.filter' | translate }}</h3>
      <div *ngFor="let item of selectedArchive.report_filter.filter | keys">
        <div class="flex layout-fields justify-space-between" *ngIf="item.key !== 'spalten'">
          <span>{{ 'archive.' + item.key | translate }}</span>
          <span *ngIf="item.key !== 'panel'">{{ selectedArchive.report_filter.filter[item.key]}}</span>
          <span *ngIf="item.key === 'panel'">{{ selectedArchive.report_filter.filter[item.key] | panelValue | async}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="p-bottom-24">
    <div *ngIf="selectedArchive.report_filter?.filter && selectedArchive.report_filter.filter.spalten && selectedArchive.report_filter.filter.spalten.length > 0">
      <h3>{{ 'archive.columns' | translate }}</h3>
      <div *ngFor="let column of selectedArchive.report_filter.filter.spalten">
        <span>{{ 'columns.' + column | translate }}</span>
      </div>
    </div>
  </div>

  <div>
    <button mat-raised-button class="action-button primary" *ngIf="selectedArchive.type === 'REPORT'" (click)="goToReport()">Zum Report</button>
  </div>
</div>
