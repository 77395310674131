import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Archive } from 'src/app/models/archive';
import { ArchiveService } from 'src/app/services/archive/archive.service';
import { Subject, takeUntil } from 'rxjs';
import { CLIENT_DATE_FORMAT } from '@shared/constants';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { MenuService } from '../../../services/menu/menu.service';
import { FilterLayoutService } from '../../../services/filter-layout/filter-layout.service';

@Component({
  selector: 'app-archive-details',
  templateUrl: './archive-details.component.html',
  styleUrls: ['./archive-details.component.scss']
})
export class ArchiveDetailsComponent implements OnInit, OnDestroy {
  selectedArchive: Archive;
  private destroy$ = new Subject<void>();

  constructor(private archiveService: ArchiveService, private router: Router, private menuService: MenuService, private filterLayoutService: FilterLayoutService) {}

  ngOnInit(): void {
    this.archiveService.selectedArchive$.pipe(takeUntil(this.destroy$)).subscribe((node) => {
      this.selectedArchive = _.cloneDeep(node);
      if (this.selectedArchive.report_filter?.filter.from_day) {
        this.selectedArchive.report_filter.filter.from_day = moment(node.report_filter?.filter.from_day).format(CLIENT_DATE_FORMAT);
      }
      if (this.selectedArchive.report_filter?.filter.to_day) {
        this.selectedArchive.report_filter.filter.to_day = moment(node.report_filter?.filter.to_day).format(CLIENT_DATE_FORMAT);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async goToReport() {
    const menu = this.menuService.getMenuByUrl(this.selectedArchive.report_filter.page);
    if (!menu) {
      throw new Error('menu not found');
    }
    this.menuService.setMenu(menu);
    await this.filterLayoutService.setSelectedFilter(this.selectedArchive.report_filter, this.selectedArchive.report_filter.mediaType, this.selectedArchive.report_filter.page);
    await this.router.navigate([this.selectedArchive.report_filter.page + '/' + this.selectedArchive.report_filter.mediaType]);
  }
}
