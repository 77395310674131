import { Component } from '@angular/core';
import { Archive, ArchiveTypeEnum, FileTypeEnum, ReportFilter } from '../../../models/archive';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ArchiveSelectFolderDialogComponent } from '../archive-select-folder-dialog/archive-select-folder-dialog.component';
import { ArchiveService } from '../../../services/archive/archive.service';
import { take } from 'rxjs';
import { AlertDialogComponent } from '../../common/alert-dialog/alert-dialog.component';
import { LoadingService } from '../../../services/loading.service';
import { LayoutEnum } from '../../filter-layout-panel/table-config/table-config.component';

@Component({
  selector: 'app-archive-save-report-dialog',
  templateUrl: './archive-save-report-dialog.component.html',
  styleUrls: ['./archive-save-report-dialog.component.scss'],
  providers: [LoadingService]
})
export class ArchiveSaveReportDialogComponent {
  selectedFolder: Archive | undefined;
  selectedArchiveType: ArchiveTypeEnum;
  selectedFilter: ReportFilter;
  selectedLayout: LayoutEnum;

  form = new FormGroup({
    name: new FormControl('', [Validators.required])
  });

  constructor(private dialog: MatDialog, private archiveService: ArchiveService, private loadingService: LoadingService) {}

  save() {
    if (this.selectedFolder) {
      const newReport = {
        name: this.form.getRawValue()?.name,
        parent_id: this.selectedFolder.id,
        report_filter: this.selectedFilter,
        type: FileTypeEnum.REPORT
      } as Archive;
      this.loadingService.loadingOn();
      this.archiveService
        .create(this.selectedArchiveType, FileTypeEnum.REPORT, newReport)
        .pipe(take(1))
        .subscribe((newReport) => {
          this.loadingService.loadingOff();
          const alertDialog = this.dialog.open(AlertDialogComponent);
          alertDialog.componentInstance.message = {
            key: 'archive.alert.createReportSuccess',
            params: { reportName: newReport.name, fullPath: this.selectedFolder?.fullPath }
          };
          alertDialog.afterClosed().subscribe(() => this.dialog.closeAll());
        });
    }
  }

  openArchiveTreeDialog() {
    let matDialogRef = this.dialog.open(ArchiveSelectFolderDialogComponent, {
      width: '1000px',
      height: '900px',
      maxHeight: '100vh'
    });
    matDialogRef.componentInstance.onFolderSelected.subscribe((data) => {
      this.selectedFolder = data.folder;
      this.selectedArchiveType = data.archiveType;
    });
  }
}
