import { Injectable } from '@angular/core';
import { AbstractStoreService } from '../abstract-store.service';
import { HttpClient } from '@angular/common/http';
import { Day, Umfeld } from '../../../models/models';
import { Observable, shareReplay } from 'rxjs';
import { environment } from '../../../../environments/environment';

const REST_ENDPOINT_URI = 'sehverhalten/digital/nutzung/day';

@Injectable({
  providedIn: 'root'
})
export class DigitalUsageDayStore extends AbstractStoreService<Day> {
  constructor(http: HttpClient) {
    super(http, REST_ENDPOINT_URI);
  }

  getByParents(umfelds: Umfeld[] | undefined): Observable<Day[]> {
    let urlParameters = '';
    if (umfelds?.length) {
      urlParameters = '?umfeld=' + encodeURIComponent(umfelds.map((umfeld) => umfeld.umfeld).join(','));
    }
    return this.http.get<Day[]>(environment.serverUrl + REST_ENDPOINT_URI + urlParameters).pipe(shareReplay());
  }
}
