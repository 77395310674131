import { Injectable } from '@angular/core';
import { ContactsData, Day, Filter, TableData } from '../../models/models';
import { AbstractFilterService } from './abstract-filter-service';
import { combineLatest, map, Observable, shareReplay } from 'rxjs';
import * as moment from 'moment';
import { SERVER_DATE_FORMAT } from '@shared/constants';
import { LoadingService } from '../loading.service';
import { AtvCustomerStore } from '../store/atv/atv-customer-store.service';
import { AtvCampaignStore } from '../store/atv/atv-campaign-store.service';
import { AtvLineItemStore } from '../store/atv/atv-lineitem-store.service';
import { AtvSiteStore } from '../store/atv/atv-site-store.service';
import { AtvDayStore } from '../store/atv/atv-day-store.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PanelStore } from '../store/panel-store.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsAtvFilterService extends AbstractFilterService {
  constructor(
    private atvCustomerStore: AtvCustomerStore,
    private atvCampaignStore: AtvCampaignStore,
    private atvLineItemStore: AtvLineItemStore,
    private atvSiteStore: AtvSiteStore,
    private atvDayStore: AtvDayStore,
    private panelStore: PanelStore,
    private loadingService: LoadingService,
    private http: HttpClient
  ) {
    super();
  }

  initRelation(): Observable<ContactsData> {
    const initialData$ = combineLatest([this.atvCustomerStore.data, this.atvCampaignStore.data, this.atvLineItemStore.data, this.atvSiteStore.data, this.atvDayStore.data, this.panelStore.data]).pipe(
      map(([customers, campaigns, lineItems, sites, day, panels]) => {
        return {
          kunde: customers,
          kampagne: campaigns,
          lineItem: lineItems,
          umfeld: sites,
          day: {
            from_day: moment(day[0].from_day, SERVER_DATE_FORMAT),
            to_day: moment(day[0].to_day, SERVER_DATE_FORMAT)
          },
          panel: panels
        } as ContactsData;
      })
    );
    initialData$.subscribe((data) => {
      this.setInitialData(data);
    });
    return this.loadingService.showLoaderUntilCompleted(initialData$);
  }

  filterChildrenServerSide(selectedFilter: Filter): Observable<ContactsData> {
    return combineLatest([
      this.atvSiteStore.getByParents(selectedFilter.kunde, selectedFilter.kampagne, selectedFilter.lineItem),
      this.atvDayStore.getByParents(selectedFilter.kunde, selectedFilter.kampagne, selectedFilter.lineItem)
    ]).pipe(
      map(
        ([sites, day]) =>
          ({
            umfeld: sites,
            day: {
              from_day: day[0].from_day ? moment(day[0].from_day, SERVER_DATE_FORMAT) : null,
              to_day: day[0].to_day ? moment(day[0].to_day, SERVER_DATE_FORMAT) : null
            } as Day
          } as ContactsData)
      )
    );
  }

  public getTableData(params: any): Observable<TableData> {
    return this.http.post<TableData>(environment.serverUrl + 'sehverhalten/atv/werbekontakt/tabelle', params).pipe(shareReplay(1));
  }
}
