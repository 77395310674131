import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Archive, ArchiveTypeEnum, CreateNode, EditNode, FileTypeEnum } from '../../models/archive';
import { Observable, shareReplay, Subject } from 'rxjs';

const FOLDER = 'folder';
const REPORT = 'report';
const SLASH = '/';
const ARCHIVE_PUBLIC = 'archive/public';
const ARCHIVE_PRIVATE = 'archive/private';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {
  private selectedArchive = new Subject<Archive>();
  public selectedArchive$ = this.selectedArchive.asObservable();

  constructor(private http: HttpClient) {}

  public setSelectedArchive(archive: Archive) {
    this.selectedArchive.next(archive);
  }

  public create(archiveType: ArchiveTypeEnum, fileType: FileTypeEnum, newNode: CreateNode): Observable<Archive> {
    if (fileType === FileTypeEnum.FOLDER) {
      return archiveType === ArchiveTypeEnum.PUBLIC ? this.createPublicFolder(newNode) : this.createPrivateFolder(newNode);
    }
    return archiveType === ArchiveTypeEnum.PUBLIC ? this.createPublicReport(newNode) : this.createPrivateReport(newNode);
  }

  public createPublicReport(newNode: CreateNode): Observable<Archive> {
    return this.http.post<Archive>(environment.serverUrl + ARCHIVE_PUBLIC + SLASH + REPORT, newNode).pipe(shareReplay(1));
  }

  public createPrivateReport(newNode: CreateNode): Observable<Archive> {
    return this.http.post<Archive>(environment.serverUrl + ARCHIVE_PRIVATE + SLASH + REPORT, newNode).pipe(shareReplay(1));
  }

  public createPublicFolder(newNode: CreateNode): Observable<Archive> {
    return this.http.post<Archive>(environment.serverUrl + ARCHIVE_PUBLIC + SLASH + FOLDER, newNode).pipe(shareReplay(1));
  }

  public createPrivateFolder(newNode: CreateNode): Observable<Archive> {
    return this.http.post<Archive>(environment.serverUrl + ARCHIVE_PRIVATE + SLASH + FOLDER, newNode).pipe(shareReplay(1));
  }

  public delete(archiveType: ArchiveTypeEnum, node: Archive) {
    if (archiveType === ArchiveTypeEnum.PUBLIC) {
      return this.http.delete<any>(environment.serverUrl + ARCHIVE_PUBLIC + SLASH + node.id);
    }
    return this.http.delete<any>(environment.serverUrl + ARCHIVE_PRIVATE + SLASH + node.id);
  }

  public update(archiveType: ArchiveTypeEnum, fileType: FileTypeEnum, updateNode: EditNode) {
    if (archiveType === ArchiveTypeEnum.PUBLIC) {
      if (fileType === FileTypeEnum.FOLDER) {
        return this.http.put<any>(environment.serverUrl + ARCHIVE_PUBLIC + SLASH + FOLDER, updateNode).pipe(shareReplay(1));
      }
      return this.http.put<any>(environment.serverUrl + ARCHIVE_PUBLIC + SLASH + REPORT, updateNode).pipe(shareReplay(1));
    }

    if (fileType === FileTypeEnum.FOLDER) {
      return this.http.put<any>(environment.serverUrl + ARCHIVE_PRIVATE + SLASH + FOLDER, updateNode).pipe(shareReplay(1));
    }
    return this.http.put<any>(environment.serverUrl + ARCHIVE_PRIVATE + SLASH + REPORT, updateNode).pipe(shareReplay(1));
  }

  public toSingleLevelDeep(data: Archive[]): Archive[] {
    const flattedArray = new Array<Archive>();
    data?.forEach((node) => {
      if (node.children) {
        flattedArray.push(...this.toSingleLevelDeep(node.children));
      }
      flattedArray.push(node);
    });
    return flattedArray;
  }

  public getPath(flattedData: Archive[], nodeId: string, path: string): string {
    const parentNode = flattedData.find((node) => node.id === nodeId);
    if (!parentNode) {
      throw new Error(`Parent node with id ${nodeId} does not exit`);
    }
    path = parentNode.name + '/' + path;
    if (parentNode.parent_id) {
      return this.getPath(flattedData, parentNode.parent_id, path);
    }
    return path;
  }

  public getFullPath(flattedData: Archive[], currentNode: Archive): string {
    let path = currentNode.name;
    if (!currentNode.parent_id) {
      return '/' + path;
    }
    return this.getPath(flattedData, currentNode.parent_id, path);
  }
}
