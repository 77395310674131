<h2>{{ 'archive.selectFolder' | translate }}</h2>
<app-loading></app-loading>
<mat-dialog-content class="default-dialog-content-height p-top-30">
  <div class="d-flex p-bottom-30">
    <div class="nav-element" [class.active]="selectedArchiveType === archiveTypeEnum.PUBLIC" (click)="changeArchiveType(archiveTypeEnum.PUBLIC)">{{ 'submenu.sharedFolder' | translate }}</div>
    <div class="nav-element" [class.active]="selectedArchiveType === archiveTypeEnum.PRIVATE" (click)="changeArchiveType(archiveTypeEnum.PRIVATE)">{{ 'submenu.myFolder' | translate }}</div>
  </div>

  <app-archive-public *ngIf="selectedArchiveType === archiveTypeEnum.PUBLIC"></app-archive-public>
  <app-archive-private *ngIf="selectedArchiveType === archiveTypeEnum.PRIVATE"></app-archive-private>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="actions-container">
    <button mat-raised-button class="action-button secondary auto" mat-dialog-close>{{ 'button.cancel' | translate }}</button>
    <button mat-raised-button class="action-button small" (click)="select()" [disabled]="!selectedFolder">{{ 'button.select' | translate }}</button>
  </div>
</mat-dialog-actions>
