import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { observableToPromise } from '../../utils/async-utils';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-token',
  templateUrl: './token.page.html',
  styleUrls: ['./token.page.scss']
})
export class TokenPage implements OnInit {
  public token: string | null;
  public showContent = false;
  public copied = false;

  constructor(private authService: AuthService, private clipboard: Clipboard) {
    this.showContent = !environment.production;
  }

  async ngOnInit() {
    const authentication = this.authService.getAuthentication();
    this.token = await observableToPromise(authentication.idToken);
  }

  copyToClipboard() {
    if (this.token) {
      this.clipboard.copy(this.token);
      this.copied = true;
    }
  }
}
