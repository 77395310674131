import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { AuthenticationProviderEnum, StorageKeyEnum } from '@shared/constants';
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from 'src/app/components/common/error-dialog/error-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private angularFireAuth: AngularFireAuth, private router: Router, private dialog: MatDialog, private translate: TranslateService) {}

  getAuthentication() {
    return this.angularFireAuth;
  }

  loginUser(authenticationProvider: AuthenticationProviderEnum): void {
    localStorage.setItem(StorageKeyEnum.LOGIN_IN_PROGRESS, 'true');
    try {
      const provider = new firebase.auth.OAuthProvider(authenticationProvider);
      this.angularFireAuth
        .signInWithPopup(provider)
        .then((success) => {})
        .catch((error) => {
          const alertDialog = this.dialog.open(ErrorDialogComponent, { width: '80%', hasBackdrop: true, disableClose: true });
          alertDialog.componentInstance.message = { key: this.translate.instant('Not authenticated'), error: error };
        });
    } catch (error) {
      localStorage.removeItem(StorageKeyEnum.LOGIN_IN_PROGRESS);
      console.log(error);
    }
  }

  async logoutUser() {
    try {
      await this.angularFireAuth.signOut();
    } catch (e) {
      console.log(e);
    } finally {
      await this.router.navigate(['/login']);
      localStorage.removeItem(StorageKeyEnum.AUTHENTICATION);
      localStorage.removeItem(StorageKeyEnum.LOGIN_IN_PROGRESS);
    }
  }
}
