import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { debounceTime, map, ReplaySubject, startWith, Subject, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { SearchFilter } from '../../../models/models';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-select',
  templateUrl: './rtl-select.component.html',
  styleUrls: ['./rtl-select.component.scss']
})
export class RtlSelectComponent implements OnInit, OnChanges, OnDestroy {
  private OPTION_HEIGHT = 51;
  private DEFAULT_VIRTUAL_CONTAINER_HEIGHT = 400;

  @Input() label: string | undefined;
  @Input() height = this.DEFAULT_VIRTUAL_CONTAINER_HEIGHT;

  @Input() allItems: any[] | undefined;
  @Input() isLoading = false;
  @Input() propertyValue!: string;
  @Input() selectedItem: any[] | undefined;
  @Input() searchFilter: SearchFilter | undefined;
  @Input() hideSelected = false;
  @Output() onItemSelect = new EventEmitter<{ values: any[]; searchFilter: SearchFilter | undefined }>();

  @Input() currentValue: any[] | undefined;
  @ViewChild('virtualScrollViewport') virtualScrollViewport: CdkVirtualScrollViewport;
  public searchCtrl = new FormControl('');
  private itemsFilteredSubject = new ReplaySubject<string[]>();
  public itemsFiltered$ = this.itemsFilteredSubject.asObservable();
  public itemsFiltered: any[] = [];
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.initSearch();
  }

  itemSelected(values: string[]) {
    this.onItemSelect.emit({ searchFilter: this.searchFilter, values });
  }

  initSearch() {
    this.searchCtrl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        startWith(''),
        debounceTime(300),
        map((value: string) => {
          //live search or introduce a new flag
          if (!this.allItems) {
            return [];
          }
          let filteredResult: any[] = this.allItems.filter((item) => item[this.propertyValue]?.toLowerCase().includes(value?.toLowerCase()));
          //offline search
          let unselected;
          if (this.currentValue?.length) {
            unselected = filteredResult.filter((item) => !this.currentValue?.some((selectedItem) => item[this.propertyValue] === selectedItem[this.propertyValue]));
            filteredResult = this.hideSelected ? unselected : [...this.currentValue, ...unselected];
          }
          this.height = filteredResult.length * this.OPTION_HEIGHT < this.DEFAULT_VIRTUAL_CONTAINER_HEIGHT ? filteredResult.length * this.OPTION_HEIGHT : this.DEFAULT_VIRTUAL_CONTAINER_HEIGHT;
          return filteredResult;
        })
      )
      .subscribe((result) => {
        this.itemsFilteredSubject.next(result);
        this.itemsFiltered = result;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['allItems']?.firstChange) {
      this.initSearch();
    }
  }

  compareWith = (o1: any, o2: any) => {
    if (o1 && o2) {
      return o1[this.propertyValue] === o2[this.propertyValue];
    }
    return false;
  };

  onClose() {
    this.virtualScrollViewport.scrollToIndex(0);
  }
}
