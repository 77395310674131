import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LayoutMediaType } from '@shared/filter-layout-config';
import { Column, ColumnFilter } from '../../../models/models';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FilterFactoryService } from '../../../services/filter-layout/filter-factory.service';
import { AbstractFilterService } from '../../../services/filter-layout/abstract-filter-service';
import { Router } from '@angular/router';

export enum LayoutEnum {
  COMPACT = 'COMPACT',
  DETAIL = 'DETAIL'
}

@Component({
  selector: 'app-table-config',
  templateUrl: './table-config.component.html',
  styleUrls: ['./table-config.component.scss']
})
export class TableConfigComponent implements OnInit {
  @Input() config: LayoutMediaType | undefined;
  @Input() mediaType: string | undefined;
  @Input() pageName: string | undefined;
  @Output() columnsChanged = new EventEmitter<Column[]>();
  public selectedLayout = LayoutEnum.COMPACT;
  public layoutEnum = LayoutEnum;
  public compactOptions = new Set<Column>();
  public detailOptions = new Set<Column>();
  public detailAdditionalOptions = new Array<Column>();
  public compactAdditionalOptions = new Array<Column>();
  public selectedItems = new Array<Column>();
  private filterService: AbstractFilterService;

  constructor(private translateService: TranslateService, private filterServiceFactoryService: FilterFactoryService, private filterFactoryService: FilterFactoryService, private router: Router) {}

  async ngOnInit() {
    // workaround: wait until the translations are loaded
    await this.translateService.get('columns.panel').toPromise();
    this.filterService = this.filterServiceFactoryService.getFilterService(this.mediaType, this.pageName);
    const selectedColumns = this.filterService.getColumns();
    this.initColumns();
    if (selectedColumns.length && this.config) {
      const selectedLayout = this.filterService.getSelectedLayout();
      this.selectedLayout = selectedLayout;
      if (selectedLayout === LayoutEnum.COMPACT) {
        const compactAll = Array.from(this.getOptions(...this.config.compact, ...this.config.compactAdditional));
        this.compactOptions = new Set(compactAll.filter((column) => selectedColumns.some((selectColumn) => selectColumn.id === column.id)));
        this.compactAdditionalOptions = compactAll.filter((column) => !selectedColumns.some((selectColumn) => selectColumn.id === column.id));
      }
      if (selectedLayout === LayoutEnum.DETAIL) {
        const detailAll = Array.from(this.getOptions(...this.config.detail, ...this.config.detailAdditional));
        this.detailOptions = new Set(detailAll.filter((column) => selectedColumns.some((selectColumn) => selectColumn.id === column.id)));
        this.detailAdditionalOptions = detailAll.filter((column) => !selectedColumns.some((selectColumn) => selectColumn.id === column.id));
      }
    } else {
      this.onLayoutChange();
    }
  }

  private initColumns() {
    if (!this.config) {
      throw new Error('Table config must not be null or empty.');
    }
    this.compactOptions = this.getOptions(...this.config.compact);
    this.detailOptions = this.getOptions(...this.config.detail);
    this.compactAdditionalOptions = Array.from(this.getOptions(...this.config.compactAdditional));
    this.compactAdditionalOptions = _.sortBy(this.compactAdditionalOptions, 'name');
    this.detailAdditionalOptions = Array.from(this.getOptions(...this.config.detailAdditional));
    this.detailAdditionalOptions = _.sortBy(this.detailAdditionalOptions, 'name');

    const allColumns = Array.from(this.detailOptions).concat(this.detailAdditionalOptions).concat(Array.from(this.compactOptions)).concat(this.compactAdditionalOptions);
    this.filterService.setAllColumns(_.uniqBy(allColumns, 'id'));
  }

  getOptions(...compact: ColumnFilter[]): Set<Column> {
    if (!compact) {
      return new Set([]);
    }
    const elementList = compact.map((columnEnum: ColumnFilter) => {
      return {
        id: columnEnum,
        name: this.translateService.instant('columns.' + columnEnum)
      };
    });
    return new Set(elementList);
  }

  onItemSelect(values: Column[]) {
    this.selectedItems = [];
    if (this.selectedLayout === LayoutEnum.COMPACT) {
      this.compactAdditionalOptions = this.compactAdditionalOptions.filter((item) => !values.some((value) => value.id === item.id));
      this.compactAdditionalOptions = _.sortBy(this.compactAdditionalOptions, 'name');
      values.forEach((value) => this.compactOptions.add(value));
      this.updateSelectedColumns(Array.from(this.compactOptions));
      return;
    }
    this.detailAdditionalOptions = this.detailAdditionalOptions.filter((item) => !values.some((value) => value.id === item.id));
    values.forEach((value) => {
      this.detailAdditionalOptions = _.sortBy(this.detailAdditionalOptions, 'name');
      this.detailOptions.add(value);
    });
    this.updateSelectedColumns(Array.from(this.detailOptions));
  }

  removeItem(column: Column) {
    if (this.selectedLayout === LayoutEnum.COMPACT) {
      this.compactOptions.delete(column);
      this.compactAdditionalOptions = [...this.compactAdditionalOptions, column];
      this.compactAdditionalOptions = _.sortBy(this.compactAdditionalOptions, 'name');
      this.updateSelectedColumns(Array.from(this.compactOptions));
    } else {
      this.detailOptions.delete(column);
      this.detailAdditionalOptions = [...this.detailAdditionalOptions, column];
      this.detailAdditionalOptions = _.sortBy(this.detailAdditionalOptions, 'name');
      this.updateSelectedColumns(Array.from(this.detailOptions));
    }
  }

  private updateSelectedColumns(columns: Column[]) {
    this.selectedItems = columns;
    this.columnsChanged.emit(columns);
  }

  onLayoutChange() {
    this.filterService.setSelectedLayout(this.selectedLayout);
    if (this.selectedLayout === LayoutEnum.COMPACT) {
      this.updateSelectedColumns(Array.from(this.compactOptions));
    } else {
      this.updateSelectedColumns(Array.from(this.detailOptions));
    }
  }
}
