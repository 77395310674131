import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';

@Injectable()
export class FirebaseInterceptor implements HttpInterceptor {
  constructor(private auth: AngularFireAuth) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes('assets/i18n/')) {
      return this.auth.authState.pipe(
        take(1),
        mergeMap((user) => this.addTokenToRequest(user, request, next))
      );
    }
    return next.handle(request);
  }

  private addTokenToRequest(user: firebase.User | null, request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!user) {
      return next.handle(request);
    }

    return from(user?.getIdToken()).pipe(
      mergeMap((token) => {
        if (token) {
          if (!request.headers.has('Authorization')) {
            request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
          }
        }
        return next.handle(request);
      })
    );
  }
}
