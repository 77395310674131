import { FilterFactoryService } from './filter-factory.service';
import { Injectable } from '@angular/core';
import { ReportFilter } from '../../models/archive';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FilterLayoutService {
  constructor(private filterFactoryService: FilterFactoryService, private translateService: TranslateService) {}

  async setSelectedFilter(reportFilter: ReportFilter, mediaType: string, pageName: string) {
    const filterService = this.filterFactoryService.getFilterService(mediaType, pageName);
    const filter = await filterService.convertToFilter(reportFilter);
    const columns = reportFilter.filter.spalten.map((columnId) => {
      return {
        id: columnId,
        name: this.translateService.instant('columns.' + columnId)
      };
    });
    filterService.setFilter(filter);
    filterService.setColumns(columns);
    filterService.setSelectedLayout(reportFilter.layout);
  }
}
