import { Injectable } from '@angular/core';
import { AbstractStoreService } from '../abstract-store.service';
import { HttpClient } from '@angular/common/http';
import { Spot } from '../../../models/models';

const REST_ENDPOINT_URI = 'sehverhalten/spots/werbekontakt/werbespotname';

@Injectable({
  providedIn: 'root'
})
export class TvSpotStore extends AbstractStoreService<Spot> {
  constructor(http: HttpClient) {
    super(http, REST_ENDPOINT_URI);
  }
}
