import * as moment from 'moment';

export interface ContactsData {
  film?: Film[];
  spot?: Spot[];
  lineItem?: LineItem[];
  day?: Day;
  mediaType?: MediaType[];
  adtype?: AdType[];

  kunde?: Customer[];
  produkt?: Product[];
  kampagne?: Campaign[];
  umfeld?: Umfeld[];
  gattung?: Gattung[];
  mapping?: Mapping[];
  sendung?: Sendung[];
  sender?: Sender[];
  panel?: Panel[];
}

export enum ColumnFilter {
  PANEL_ID = 'panel',
  PANEL_NAME = 'panel_klarname',
  PANELYST_ID = 'panelistid',
  CONTACT = 'kontakte',
  DEVICE = 'devicegroup',
  DEVICE_PIXEL = 'device',
  DATE = 'day',
  DAYTIME = 'daytime',
  SPOT = 'werbespotname',
  PRODUCT = 'produkt',
  FILM = 'film',
  AD_TYPE = 'adtype',
  MEDIA_TYPE = 'gattung',
  AGE = 'age',
  GENDER = 'gender',
  LINE_ITEM = 'lineItem',

  KUNDE = 'kunde',
  KAMPAGNE = 'kampagne',
  GATTUNG = 'gattung',
  UMFELD = 'umfeld',
  SENDUNG = 'sendung',
  SENDER = 'sender',
  ENVIRONMENT = 'environment'
}

export enum SearchFilter {
  LINE_ITEM = 'lineItem',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  PRODUCT = 'produkt',
  FILM = 'film',
  SPOT = 'spot',
  MEDIA_TYPE = 'mediaType',
  MAPPING = 'mapping',
  PIXEL_NAME = 'pixelName',
  AD_TYPE = 'adtype',
  SENDER = 'sender',
  SENDUNG = 'sendung',
  DATE = 'date',

  KUNDE = 'kunde',
  KAMPAGNE = 'kampagne',
  GATTUNG = 'gattung',
  UMFELD = 'umfeld',
  PANEL = 'panel'
}

export interface Filter {
  kunde?: Customer[] | undefined;
  produkt?: Product[] | undefined;
  film?: Film[] | undefined;
  spot?: Spot[] | undefined;
  lineItem?: LineItem[] | undefined;
  campaign?: Campaign[] | undefined;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  startDayTime: moment.Moment | undefined;
  endDayTime: moment.Moment | undefined;
  mapping: Mapping[] | undefined;
  adtype: AdType[] | undefined;
  mediaType?: MediaType[] | undefined;

  kampagne?: Campaign[] | undefined;
  umfeld?: Umfeld[] | undefined;
  gattung?: MediaType[] | undefined;
  sendung?: Sendung[] | undefined;
  sender?: Sender[] | undefined;
  panel?: Panel[] | undefined;
}

export type ValueOfSearchFilter = `${SearchFilter}`;
export type SearchFilterRepo = {
  [P in ValueOfSearchFilter]: string;
};

export const filterTypeRepo: SearchFilterRepo = {
  lineItem: 'lineitem',
  produkt: 'produkt',
  film: 'film',
  spot: 'werbespotname',
  startDate: 'from_day',
  endDate: 'to_day',
  mediaType: 'mediaType',
  pixelName: 'pixelName',
  adtype: 'adtype',
  sender: 'sender',
  sendung: 'sendung',
  date: 'date',

  kunde: 'kunde',
  kampagne: 'kampagne',
  gattung: 'gattung',
  umfeld: 'umfeld',
  mapping: 'mapping',
  panel: 'panel'
};

export interface Relation {
  rootParent: SearchFilter | null;
  parents: SearchFilter[];
  children: SearchFilter[];
}

export interface Column {
  id: string;
  name: string;
}

export interface Panel {
  panel: string;
  panel_klarname: string;
}

export interface Customer {
  kunde: string;
  gattung?: string[];
}

export interface Product {
  produkt: string;
  kunde: string;
}

export interface Film {
  film: string;
  produkt: string;
  kunde: string;
}

export interface Spot {
  werbespotname: string;
  film: string;
  produkt: string;
  kunde: string;
}

export interface Campaign {
  kampagne: string;
  kunde: string;
  gattung?: string[];
}

export interface LineItem {
  lineitem: string;
  kampagne: string;
  kunde: string;
  gattung?: string[];
}

export interface TablePixel {
  site: string;
}

export interface Mapping {
  mapping: string;
}

export interface AdType {
  adtype: string;
}

export interface MediaType {
  gattung: string;
}

export interface Gattung {
  gattung: string;
}

export interface Umfeld {
  umfeld: string;
}

export interface Sendung {
  sendung: string;
}

export interface Sender {
  sender: string;
}
export interface Day {
  from_day: moment.Moment;
  to_day: moment.Moment;
}

export interface Datenstand {
  datenstand: string;
}

export interface TableData {
  data: any[];
  schema: any[];
  num_panelists: number;
}

export interface ProfileSegment {
  id: string;
  name: string;
  variable_type: string;
  category: string;
  categorySortOrder: number;
}

export interface ProfileSegmentOption {
  id: string;
  name: string;
}
