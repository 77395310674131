<div class="login-container">
  <div class="logo-text">
    <span><strong>Panel</strong></span
    >ytics
  </div>
  <div class="login-provider" (click)="loginWithRTLAccount()">
    <div style="height: 100px">
      <img src="assets/images/rtl_logo.svg" class="rtl-logo" />
    </div>
    <div>RTL Active Directory</div>
  </div>

  <div class="login-provider" (click)="loginWithAdAllianceAccount()">
    <img src="assets/images/adalliance.svg" width="100" />
    <div>Ad Alliance Active Directory</div>
  </div>
</div>
