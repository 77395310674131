<mat-label *ngIf="label">{{ label }}</mat-label> <br />

<mat-form-field class="form-field full-width" appearance="fill">
  <mat-select [(ngModel)]="currentValue" (ngModelChange)="itemSelected($event)" (closed)="onClose()" [compareWith]="compareWith" placeholder="{{ 'form.placeholder.select' | translate }}" disableOptionCentering multiple>
    <mat-select-trigger *ngIf="currentValue && currentValue.length > 1">
      <span *ngIf="currentValue && currentValue.length > 1">{{ currentValue?.length }} ausgewählt</span>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search [formControl]="searchCtrl" placeholderLabel="{{ 'form.placeholder.startSearch' | translate }}"></ngx-mat-select-search>
    </mat-option>

    <cdk-virtual-scroll-viewport #virtualScrollViewport [itemSize]="51" [minBufferPx]="500" [maxBufferPx]="1000" [appendOnly]="true" [ngStyle]="{'height.px': height}">
        <mat-option *cdkVirtualFor="let option of itemsFiltered; let i = index" [value]="option">
          <span [innerHTML]="option[propertyValue] "></span>
        </mat-option>
      </cdk-virtual-scroll-viewport>
  </mat-select>
</mat-form-field>
