import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoadingService } from '../../services/loading.service';
import { NavigationEnd, Router } from '@angular/router';
import { TableService } from '../../services/table/table-service';
import { Column, Filter } from '../../models/models';
import { filter, Subject, takeUntil } from 'rxjs';
import { RtlGridComponent, TableColumns } from '../../components/rtl-grid/rtl-grid.component';
import * as moment from 'moment';
import { GeneralService } from '../../services/store/general/general.service';
import { CLIENT_DATE_TIME_FORMAT } from '@shared/constants';

@Component({
  selector: 'app-contacts-media-type',
  templateUrl: './default-media-type.page.html',
  styleUrls: ['./default-media-type.page.scss']
})
export class DefaultMediaTypePage implements OnInit, OnDestroy {
  @ViewChild(RtlGridComponent) gridChild: RtlGridComponent;
  public data: any[];
  public scheme: string[];
  public columns: TableColumns[];
  public result: number | undefined;
  public isFilterOpen = true;
  public mediaType: string | undefined;
  public exportFileName: string | undefined;
  public pageName: string | undefined;

  private destroy$ = new Subject<void>();

  constructor(private loadingService: LoadingService, private router: Router, private tableService: TableService, private generalService: GeneralService) {}

  ngOnInit(): void {
    this.extractInfoFromUrl();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.extractInfoFromUrl();
    });
    this.exportFileName = `Panel_${this.mediaType}_${moment().format('YYYYMMDD_HHmm')}`;
    this.generalService.getDatenstand().subscribe();
  }

  private extractInfoFromUrl() {
    const paths = this.router.url.split('/');
    if (paths.length === 3) {
      this.mediaType = paths[2];
      this.pageName = paths[1];
    }
  }

  async loadData(filter: Filter | undefined, selectedColumns: Column[], allColumns: Column[]) {
    if (!this.mediaType) {
      throw new Error('Media type must not be null or empty');
    }
    if (!this.pageName) {
      throw new Error('Page name must not be null or empty');
    }
    this.initTableColumns(selectedColumns);
    this.loadingService.loadingOn();
    this.tableService
      .getData(filter, allColumns, this.mediaType, this.pageName)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (tableData) => {
          this.scheme = tableData.schema;
          this.data = tableData.data;
          this.result = tableData.num_panelists;
          this.loadingService.loadingOff();
        },
        error: (err) => {
          this.loadingService.loadingOff();
          console.error(err);
        }
      });
  }

  onFilterToggled(isFilterOpen: boolean) {
    this.isFilterOpen = isFilterOpen;
  }

  private initTableColumns(columns: Column[]) {
    this.columns = columns.map((column) => {
      if (column.id === 'daytime') {
        return { field: column.id, name: 'columns.' + column.id, width: 150, valueFormatter: this.dateFormatter } as TableColumns;
      }
      return { field: column.id, name: 'columns.' + column.id, width: 150 } as TableColumns;
    });
  }

  dateFormatter = (value: string): string => {
    if (!value) {
      return '';
    }
    return moment(value).format(CLIENT_DATE_TIME_FORMAT);
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
