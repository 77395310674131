import { Injectable } from '@angular/core';
import { AbstractStoreService } from '../abstract-store.service';
import { HttpClient } from '@angular/common/http';
import { Mapping } from '../../../models/models';

const REST_ENDPOINT_URI = 'sehverhalten/digital/werbekontakt/mapping';

@Injectable({
  providedIn: 'root'
})
export class DigitalMappingnStore extends AbstractStoreService<Mapping> {
  constructor(http: HttpClient) {
    super(http, REST_ENDPOINT_URI);
  }
}
