import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { ArchiveSaveReportDialogComponent } from '../../archive/archive-save-report-dialog/archive-save-report-dialog.component';
import { FilterFactoryService } from '../../../services/filter-layout/filter-factory.service';
import moment from 'moment';
import { CLIENT_DATE_FORMAT, SERVER_DATE_FORMAT } from '@shared/constants';
import { GeneralService } from 'src/app/services/store/general/general.service';
import { Column } from 'src/app/models/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-data-result-box',
  templateUrl: './data-result-box.component.html',
  styleUrls: ['./data-result-box.component.scss']
})
export class DataResultBoxComponent implements OnInit, OnDestroy {
  @Input() result: number | undefined;
  @Input() mediaType: string | undefined;
  @Input() pageName: string | undefined;
  isLoading = false;
  filteredData: any;
  lastUpdate: string | undefined;
  generalServiceDataSub: Subscription;

  constructor(private loadingService: LoadingService, private dialog: MatDialog, private filterFactoryService: FilterFactoryService, private generalService: GeneralService) {}

  ngOnInit() {
    this.filteredData = this.filterFactoryService.getFilterService(this.mediaType, this.pageName);
    this.generalServiceDataSub = this.generalService.getDatenstand().subscribe((data) => {
      this.lastUpdate = moment(data[0]?.datenstand, SERVER_DATE_FORMAT).format(CLIENT_DATE_FORMAT);
    });
  }

  async saveToArchive() {
    if (!this.mediaType || !this.pageName) {
      throw new Error('Media type or page name must not be empty.');
    }
    const filterService = this.filteredData;
    const filter = filterService.getDataRequestPayload();
    filter.spalten = filterService.getColumns().map((column: Column) => column.id);
    const dialogRef = this.dialog.open(ArchiveSaveReportDialogComponent, {
      width: '900px',
      hasBackdrop: true,
      disableClose: true
    });
    dialogRef.componentInstance.selectedFilter = {
      page: this.pageName,
      mediaType: this.mediaType,
      layout: await filterService.getSelectedLayout(),
      filter
    };
  }

  ngOnDestroy() {
    this.generalServiceDataSub.unsubscribe();
  }
}
