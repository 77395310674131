import packageInfo from '../../package.json';

export const environment = {
  production: false,
  version: packageInfo.version,
  serverUrl: 'https://app.panelytics.dev.da-service.io/api/',
  firebaseConfig: {
    apiKey: 'AIzaSyClhWHoAHfaPnBaEerXU-Cvj1UNJg5WmRs',
    authDomain: 'auth.panelytics.dev.da-service.io',
    projectId: 'mgr-panelytics-dev-4c80',
    storageBucket: 'mgr-panelytics-dev-4c80.appspot.com',
    messagingSenderId: '718247706821',
    appId: '1:718247706821:web:2c10c1efcb7b6a3978b0ac'
  },
  getProductEmail: 'VLRTLDproductspanelytics@rtl.de'
};
