import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { Column, Filter, filterTypeRepo, TableData } from 'src/app/models/models';
import * as moment from 'moment';
import { SERVER_DATE_FORMAT, STAMMDATEN_COLUMN_AGE, STAMMDATEN_COLUMN_GENDER } from '@shared/constants';
import _, { pickBy } from 'lodash';
import { FilterFactoryService } from '../filter-layout/filter-factory.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  constructor(private http: HttpClient, private contactsFilterFactoryService: FilterFactoryService) {}

  public getData(filter: Filter | undefined, allColumns: Column[], mediaType: string, pageName: string): Observable<TableData> {
    const service = this.contactsFilterFactoryService.getFilterService(mediaType, pageName);
    const stammdatenColumns = service.getColumns().filter((column) => column.id === STAMMDATEN_COLUMN_AGE || column.id === STAMMDATEN_COLUMN_GENDER);
    const tableColumns = service.getColumns().filter((column) => column.id !== STAMMDATEN_COLUMN_AGE && column.id !== STAMMDATEN_COLUMN_GENDER);
    const tableData$ = this.getTableData(filter, tableColumns, mediaType, pageName);
    return tableData$.pipe(
      switchMap((tableData) => {
        if (stammdatenColumns.length) {
          const panelistidIndex = tableData.schema.indexOf('panelistid');

          const panelistIds = tableData.data.map((data) => data[panelistidIndex]);
          return this.getMasterData(panelistIds, stammdatenColumns).pipe(
            map((masterData) => {
              const data = tableData.data.map((dataElement) => {
                let result = masterData.find((master) => master.panelist_id === dataElement[panelistidIndex]);
                result = _.omit(result, 'panelist_id');
                if (result) {
                  dataElement = [...dataElement, ..._.values(result)];
                  return dataElement;
                }
                return dataElement;
              });
              tableData.schema.push(...stammdatenColumns.map((c) => c.id));
              tableData.data = data;
              return tableData;
            })
          );
        }
        service.setTableData(tableData);
        return of(tableData);
      })
    );
  }

  public getMasterData(panelystIds: string[], columns: Column[]): Observable<any[]> {
    const selectedColumns = columns.map((column) => column.id);
    selectedColumns.push('panelist_id');
    return this.http
      .post<any[]>(environment.serverUrl + 'stammdaten/', {
        panelistenid: panelystIds,
        columns: selectedColumns
      })
      .pipe(shareReplay(1));
  }

  public getTableData(filter: Filter | undefined, columns: Column[], mediaType: string, pageName: string): Observable<TableData> {
    const service = this.contactsFilterFactoryService.getFilterService(mediaType, pageName);
    const payload = this.createPayload(filter, columns);
    service.setDataRequestPayload(payload);
    return service.getTableData(payload);
  }

  public createPayload(filter: Filter | undefined, columns: Column[]): any {
    const cleanedFilter = pickBy(filter, (v) => v !== undefined);
    const payload = {
      spalten: columns.map((column) => column.id)
    };
    Object.keys(cleanedFilter).forEach((key) => {
      const repoKey = filterTypeRepo[key];
      const filterValue = cleanedFilter[key];
      if (filterValue !== undefined && filterValue != null) {
        let value;
        if (Array.isArray(filterValue) && filterValue.length) {
          value = filterValue.map((object: any) => object[repoKey]);
        }
        if (!Array.isArray(filterValue) && filterValue.length) {
          value = Array.of(filterValue.map((object: any) => object[repoKey]));
        } else if (filterValue instanceof moment) {
          value = moment(filterValue).format(SERVER_DATE_FORMAT);
        }
        if (value && value !== '') {
          payload[repoKey] = value;
        }
      }
    });
    return payload;
  }
}
