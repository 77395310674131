<div class="page-content-new">
  <div class="content-left-new">
    <div class="d-flex justify-content-center fs-m p-top-30" *ngIf="result === undefined">
      <div>{{'searchResult.noFilterSet' | translate}}</div>
    </div>

    <div class="result-box-container p-top-30" *ngIf="result !== undefined">
      <app-data-result-box [result]="result" [mediaType]="mediaType" [pageName]="pageName"></app-data-result-box>
    </div>
    <div class="table-box-container" *ngIf="result">
      <app-rtl-grid [columns]="columns" [source]="data" [fileName]="exportFileName" [dataScheme]="scheme"></app-rtl-grid>
    </div>
  </div>
  <div class="col col-4 content-right-new" [ngClass]="isFilterOpen ? 'opened' : 'closed'">
    <div class="left">
      <app-filter-layout-panel
        [mediaType]="mediaType"
        [pageName]="pageName"
        (loadData)="loadData($event.filter, $event.selectedColumns, $event.allColumns)"
        (filterToggled)="onFilterToggled($event)"></app-filter-layout-panel>
    </div>
  </div>
</div>
