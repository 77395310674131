import { Component, OnInit } from '@angular/core';
import { Menu, MenuService } from '../../services/menu/menu.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sub-menu-selection',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit {
  public currentMenu$: Observable<Menu> | undefined;

  constructor(private menuService: MenuService) {}

  ngOnInit(): void {
    this.currentMenu$ = this.menuService.currentMenu$;
  }
}
