import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '../components/header/header.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RtlGridComponent } from '../components/rtl-grid/rtl-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { FilterLayoutPanelComponent } from '../components/filter-layout-panel/filter-layout-panel.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FilterComponent } from '../components/filter-layout-panel/filter/filter.component';
import { TableConfigComponent } from '../components/filter-layout-panel/table-config/table-config.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RtlSelectComponent } from '../components/form/rtl-select/rtl-select.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTreeModule } from '@angular/material/tree';
import { ArchiveTreeComponent } from '../components/archive/archive-tree/archive-tree.component';
import { ArchiveCreateFolderDialogComponent } from '../components/archive/archive-create-folder-dialog/archive-create-folder-dialog.component';
import { ComfirmationDialogComponent } from '../components/common/comfirmation-dialog/comfirmation-dialog.component';
import { SubHeaderComponent } from '../components/sub-header/sub-header.component';
import { ArchiveSaveReportDialogComponent } from '../components/archive/archive-save-report-dialog/archive-save-report-dialog.component';
import { ArchivePublicPage } from '../pages/archive/archive-public/archive-public.page';
import { ArchivePrivatePage } from '../pages/archive/archive-private/archive-private.page';
import { ArchiveSelectFolderDialogComponent } from '../components/archive/archive-select-folder-dialog/archive-select-folder-dialog.component';
import { ArchiveDetailsComponent } from '../components/archive/archive-details/archive-details.component';
import { KeysPipe } from '../pipes/keys.pipe';
import { AlertDialogComponent } from '../components/common/alert-dialog/alert-dialog.component';
import { ErrorDialogComponent } from '../components/common/error-dialog/error-dialog.component';
import { LoadingComponent } from '../components/loading/loading.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PanelValuePipe } from '../pipes/report-filter-value.pipe';
import { DataResultBoxComponent } from '../components/common/data-result-box/data-result-box.component';
import { DefaultMediaTypePage } from '../pages/default-media-type-page/default-media-type.page';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const materialModules = [
  MatAutocompleteModule,
  MatInputModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatDividerModule,
  MatChipsModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatCheckboxModule,
  MatSelectModule,
  MatDialogModule,
  MatDatepickerModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatSnackBarModule,
  NgxMatSelectSearchModule,
  MatPaginatorModule,
  MatTableModule,
  MatSortModule,
  MatTreeModule
];

const formComponents = [RtlSelectComponent];
const archiveComponents = [
  ArchivePublicPage,
  ArchivePrivatePage,
  ArchiveDetailsComponent,
  ArchiveTreeComponent,
  ArchiveCreateFolderDialogComponent,
  ArchiveSaveReportDialogComponent,
  ArchiveSelectFolderDialogComponent
];
const commonComponents = [ComfirmationDialogComponent, AlertDialogComponent, ErrorDialogComponent];
@NgModule({
  declarations: [
    KeysPipe,
    PanelValuePipe,
    HeaderComponent,
    RtlGridComponent,
    SubHeaderComponent,
    FilterLayoutPanelComponent,
    FilterComponent,
    TableConfigComponent,
    ...formComponents,
    ...archiveComponents,
    ...commonComponents,
    LoadingComponent,
    DataResultBoxComponent,
    DefaultMediaTypePage
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    ...materialModules,
    AgGridModule,
    FormsModule,
    MatSelectInfiniteScrollModule,
    ScrollingModule
  ],
  exports: [
    HeaderComponent,
    RtlGridComponent,
    TranslateModule,
    SubHeaderComponent,
    FilterLayoutPanelComponent,
    ...materialModules,
    FilterComponent,
    TableConfigComponent,
    ...formComponents,
    ...archiveComponents,
    ...commonComponents,
    LoadingComponent,
    KeysPipe,
    DataResultBoxComponent,
    DefaultMediaTypePage,
    KeysPipe,
    PanelValuePipe
  ]
})
export class SharedModule {}
