import { Injectable } from '@angular/core';
import { AbstractStoreService } from '../abstract-store.service';
import { HttpClient } from '@angular/common/http';
import { Campaign, Customer, Day, LineItem } from '../../../models/models';
import { Observable, shareReplay } from 'rxjs';
import { environment } from '../../../../environments/environment';

const REST_ENDPOINT_URI = 'sehverhalten/digital/werbekontakt/day';

@Injectable({
  providedIn: 'root'
})
export class DigitalDayStore extends AbstractStoreService<Day> {
  constructor(http: HttpClient) {
    super(http, REST_ENDPOINT_URI);
  }

  getByParents(customers: Customer[] | undefined, campaigns: Campaign[] | undefined, lineItems: LineItem[] | undefined): Observable<Day[]> {
    let urlParameters = '';
    if (lineItems?.length) {
      urlParameters = '?lineitem=' + encodeURIComponent(lineItems.map((item) => item.lineitem).join(','));
    } else if (campaigns?.length) {
      urlParameters = '?campaign=' + encodeURIComponent(campaigns.map((item) => item.kampagne).join(','));
    } else if (customers?.length) {
      urlParameters = '?kunde=' + encodeURIComponent(customers.map((item) => item.kunde).join(','));
    }
    return this.http.get<Day[]>(environment.serverUrl + REST_ENDPOINT_URI + urlParameters).pipe(shareReplay());
  }
}
