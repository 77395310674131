<div *ngIf="config?.includes(searchFilter.SENDER)">
  <app-select
    [label]="'form.label.sender' | translate"
    [currentValue]="selectedFilter.sender"
    [allItems]="data?.sender"
    [searchFilter]="searchFilter.SENDER"
    propertyValue="sender"
    (onItemSelect)="onItemSelectDefault($event.searchFilter, $event.values)"
    [isLoading]="currentSearchFilter !== searchFilter.SENDER && isRelationLoading"></app-select>
</div>

<div *ngIf="config?.includes(searchFilter.SENDUNG)">
  <app-select
    [label]="'form.label.sendung' | translate"
    [currentValue]="selectedFilter.sendung"
    [allItems]="data?.sendung"
    [searchFilter]="searchFilter.SENDUNG"
    propertyValue="sendung"
    (onItemSelect)="onItemSelectDefault($event.searchFilter, $event.values)"
    [isLoading]="currentSearchFilter !== searchFilter.SENDUNG && isRelationLoading"></app-select>
</div>

<div *ngIf="config?.includes(searchFilter.MAPPING)">
  <app-select
    [label]="'form.label.mapping' | translate"
    [currentValue]="selectedFilter.mapping"
    [allItems]="data?.mapping"
    [searchFilter]="searchFilter.MAPPING"
    propertyValue="mapping"
    (onItemSelect)="onItemSelectWithoutFilter($event.searchFilter, $event.values)"
    [isLoading]="currentSearchFilter !== searchFilter.MAPPING && isRelationLoading"></app-select>
  <div class="divider">
    <mat-divider></mat-divider>
  </div>
</div>

<div *ngIf="config?.includes(searchFilter.GATTUNG)">
  <app-select
    [label]="'form.label.mediaType' | translate"
    [currentValue]="selectedFilter.gattung"
    [allItems]="data?.gattung"
    [searchFilter]="searchFilter.GATTUNG"
    propertyValue="gattung"
    (onItemSelect)="onItemSelectDefault($event.searchFilter, $event.values)"
    [isLoading]="currentSearchFilter !== searchFilter.GATTUNG && isRelationLoading"></app-select>
</div>

<div *ngIf="config?.includes(searchFilter.AD_TYPE)">
  <app-select
    [label]="'form.label.adtype' | translate"
    [currentValue]="selectedFilter.adtype"
    [allItems]="data?.adtype"
    [searchFilter]="searchFilter.AD_TYPE"
    propertyValue="adtype"
    (onItemSelect)="onItemSelectWithoutFilter($event.searchFilter, $event.values)"
    [isLoading]="currentSearchFilter !== searchFilter.AD_TYPE && isRelationLoading"></app-select>
  <div class="divider">
    <mat-divider></mat-divider>
  </div>
</div>

<div *ngIf="config?.includes(searchFilter.KUNDE)">
  <app-select
    [label]="'form.label.customer' | translate"
    [currentValue]="selectedFilter.kunde"
    [allItems]="data?.kunde"
    [searchFilter]="searchFilter.KUNDE"
    propertyValue="kunde"
    (onItemSelect)="onItemSelectDefault($event.searchFilter, $event.values)"
    [isLoading]="currentSearchFilter !== searchFilter.KUNDE && isRelationLoading"></app-select>
</div>

<div *ngIf="config?.includes(searchFilter.PRODUCT)">
  <app-select
    [label]="'form.label.product' | translate"
    [currentValue]="selectedFilter.produkt"
    [allItems]="data?.produkt"
    [searchFilter]="searchFilter.PRODUCT"
    propertyValue="produkt"
    (onItemSelect)="onItemSelectDefault($event.searchFilter, $event.values)"
    [isLoading]="currentSearchFilter !== searchFilter.PRODUCT && isRelationLoading"></app-select>
</div>

<div *ngIf="config?.includes(searchFilter.FILM)">
  <app-select
    [label]="'form.label.film' | translate"
    [currentValue]="selectedFilter.film"
    [allItems]="data?.film"
    [searchFilter]="searchFilter.FILM"
    propertyValue="film"
    (onItemSelect)="onItemSelectDefault($event.searchFilter, $event.values)"
    [isLoading]="currentSearchFilter !== searchFilter.FILM && isRelationLoading"></app-select>
</div>

<div *ngIf="config?.includes(searchFilter.SPOT)">
  <app-select
    [label]="'form.label.spot' | translate"
    [currentValue]="selectedFilter.spot"
    [allItems]="data?.spot"
    [searchFilter]="searchFilter.SPOT"
    propertyValue="werbespotname"
    (onItemSelect)="onItemSelectDefault($event.searchFilter, $event.values)"
    [isLoading]="currentSearchFilter !== searchFilter.SPOT && isRelationLoading"></app-select>
</div>

<div *ngIf="config?.includes(searchFilter.KAMPAGNE)">
  <app-select
    [label]="'form.label.campaign' | translate"
    [currentValue]="selectedFilter.kampagne"
    [allItems]="data?.kampagne"
    propertyValue="kampagne"
    [searchFilter]="searchFilter.KAMPAGNE"
    (onItemSelect)="onItemSelectDefault($event.searchFilter, $event.values)"
    [isLoading]="currentSearchFilter !== searchFilter.KAMPAGNE && isRelationLoading"></app-select>
</div>

<div *ngIf="config?.includes(searchFilter.LINE_ITEM)">
  <app-select
    [label]="'form.label.lineItem' | translate"
    [currentValue]="selectedFilter.lineItem"
    [allItems]="data?.lineItem"
    propertyValue="lineitem"
    [searchFilter]="searchFilter.LINE_ITEM"
    (onItemSelect)="onItemSelectDefault($event.searchFilter, $event.values)"
    [isLoading]="currentSearchFilter !== searchFilter.LINE_ITEM && isRelationLoading"></app-select>
</div>

<div *ngIf="config?.includes(searchFilter.UMFELD)">
  <div class="divider">
    <mat-divider></mat-divider>
  </div>
  <app-select
    [label]="'form.label.site' | translate"
    [currentValue]="selectedFilter.umfeld"
    [allItems]="data?.umfeld"
    propertyValue="umfeld"
    [searchFilter]="searchFilter.UMFELD"
    (onItemSelect)="onItemSelectWithoutFilter($event.searchFilter, $event.values)"
    [isLoading]="currentSearchFilter !== searchFilter.UMFELD && isRelationLoading"></app-select>
</div>

<div *ngIf="config?.includes(searchFilter.START_DATE)">
  <mat-label>{{ 'form.label.startDate' | translate }}</mat-label>
  <mat-form-field class="full-width fs-m datepicker-field" appearance="fill">
    <input
      matInput
      [matDatepicker]="dateFrom"
      [(ngModel)]="selectedFilter.startDate"
      (ngModelChange)="onDateChange()"
      [min]="data?.day?.from_day"
      [max]="selectedFilter?.endDate || data?.day?.to_day"
      placeholder="{{ 'form.placeholder.selectDate' | translate }}" />
    <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
    <mat-datepicker #dateFrom></mat-datepicker>
  </mat-form-field>
</div>

<div *ngIf="config?.includes(searchFilter.END_DATE)">
  <mat-label>{{ 'form.label.endDate' | translate }}</mat-label>
  <mat-form-field class="full-width fs-m datepicker-field" appearance="fill">
    <input
      matInput
      [matDatepicker]="dateTo"
      [(ngModel)]="selectedFilter.endDate"
      (ngModelChange)="onDateChange()"
      [min]="selectedFilter?.startDate || data?.day?.from_day"
      [max]="data?.day?.to_day"
      placeholder="{{ 'form.placeholder.selectDate' | translate }}" />
    <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
    <mat-datepicker #dateTo></mat-datepicker>
  </mat-form-field>
</div>

<div *ngIf="config?.includes(searchFilter.PANEL)">
  <div class="divider">
    <mat-divider></mat-divider>
  </div>
  <app-select
    [label]="'form.label.panel' | translate"
    [currentValue]="selectedFilter.panel"
    [allItems]="data?.panel"
    propertyValue="panel_klarname"
    [searchFilter]="searchFilter.PANEL"
    (onItemSelect)="onItemSelectWithoutFilter($event.searchFilter, $event.values)"
    [isLoading]="currentSearchFilter !== searchFilter.PANEL && isRelationLoading"></app-select>
</div>
