<mat-dialog-content class="p-bottom-30 fs-m">
  <div class="text-center">
    <div class="p-top-30 fs-m2">
      {{ message.key | translate: message.params }}
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="actions-container">
    <button mat-raised-button class="action-button secondary small" mat-dialog-close>{{ 'button.back' | translate }}</button>
    <button mat-raised-button class="action-button small" [matDialogClose]="true" (click)="confirm()">
      <span>{{ 'button.yes' | translate }}</span>
    </button>
  </div>
</mat-dialog-actions>
