import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface Menu {
  title: string;
  url: string;
  submenus?: Menu[];
  disabled?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menuSubject = new ReplaySubject<Menu>(1);
  currentMenu$ = this.menuSubject.asObservable();

  public menus: Menu[] = [
    {
      title: 'menu.contacts',
      url: 'contacts',
      submenus: [
        { title: 'submenu.tv', url: 'tv' },
        { title: 'submenu.atv', url: 'atv' },
        { title: 'submenu.digital', url: 'digital' },
        { title: 'submenu.mafo', url: 'pixel' },
        { title: 'submenu.crossmedia', url: 'crossmedia', disabled: true },
        { title: 'submenu.audio', url: 'audio', disabled: true },
        { title: 'submenu.print', url: 'print', disabled: true }
      ]
    },
    {
      title: 'menu.usage',
      url: 'usage',
      submenus: [
        { title: 'submenu.tv', url: 'tv' },
        { title: 'submenu.digital', url: 'digital' },
        { title: 'submenu.livestream', url: 'livestream', disabled: true },
        { title: 'submenu.crossmedia', url: 'crossmedia', disabled: true }
      ]
    },
    {
      title: 'menu.profiles',
      url: 'profiles',
      submenus: []
    },
    {
      title: 'menu.segments',
      url: 'segments',
      disabled: true,
      submenus: []
    },
    {
      title: 'menu.archive',
      url: 'archive',
      submenus: [
        { title: 'submenu.sharedFolder', url: 'sharedFolder' },
        { title: 'submenu.myFolder', url: 'myFolder' }
      ]
    }
  ];

  setMenu(menu: Menu) {
    this.menuSubject.next(menu);
  }

  getMenuByUrl(url: string): Menu | undefined {
    return this.menus.find((menu) => menu.url === url);
  }
}
