<h2 *ngIf="editMode === editModeEnum.CREATE && (!currentItem || currentItem?.type === fileTypeEnum.FOLDER)">{{ 'archive.createFolder' | translate }}</h2>
<h2 *ngIf="editMode === editModeEnum.CREATE && currentItem?.type === fileTypeEnum.REPORT">{{ 'archive.createReport' | translate }}</h2>
<h2 *ngIf="editMode === editModeEnum.UPDATE && currentItem?.type === fileTypeEnum.FOLDER">{{ 'archive.editFolder' | translate }}</h2>
<h2 *ngIf="editMode === editModeEnum.UPDATE && currentItem?.type === fileTypeEnum.REPORT">{{ 'archive.editReport' | translate }}</h2>
<h2 *ngIf="editMode === editModeEnum.COPY && currentItem?.type === fileTypeEnum.REPORT">{{ 'archive.copyReport' | translate }}</h2>

<div *ngIf="editMode === editModeEnum.COPY">
  {{ 'archive.copyReportText' | translate }}
</div>

<mat-dialog-content class="create-folder-dialog p-top-30">
  <form [formGroup]="form">
    <mat-form-field class="form-field full-width fs-m" appearance="fill">
      <input matInput size="30" formControlName="name" autocomplete="off" placeholder="{{ currentItem?.type === fileTypeEnum.REPORT ? 'Berichtname' : 'Ordnername' }}" />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="actions-container">
    <button mat-raised-button class="action-button secondary auto" mat-dialog-close>{{ 'button.cancel' | translate }}</button>
    <button mat-raised-button class="action-button small" (click)="save()" *ngIf="editMode === editModeEnum.CREATE" [disabled]="form.invalid || !form.dirty">{{ 'button.create' | translate }}</button>
    <button mat-raised-button class="action-button small" (click)="update()" *ngIf="editMode === editModeEnum.UPDATE" [disabled]="form.invalid || !form.dirty">{{ 'button.save' | translate }}</button>
    <button mat-raised-button class="action-button small" (click)="copy()" *ngIf="editMode === editModeEnum.COPY" [disabled]="form.invalid || !form.dirty">{{ 'button.copy' | translate }}</button>
  </div>
</mat-dialog-actions>
