import { LayoutEnum } from '../components/filter-layout-panel/table-config/table-config.component';

export enum FileTypeEnum {
  FOLDER = 'FOLDER',
  REPORT = 'REPORT'
}

export enum ArchiveTypeEnum {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export enum EditModeEnum {
  CREATE,
  UPDATE,
  COPY
}

export interface Archive {
  type: FileTypeEnum;
  created_at: string;
  parent_id: string | null;
  name: string;
  id: string;
  created_by_user_email: string;
  children?: Archive[];
  hide: boolean;
  show: boolean;
  report_filter: ReportFilter;
  fullPath?: string;
}

export interface CreateNode {
  parent_id: string | null;
  name: string;
  type: FileTypeEnum;
  report_filter?: ReportFilter;
}

export interface EditNode {
  id: string;
  name: string;
  report_filter?: ReportFilter;
}

export interface ReportFilter {
  page: string;
  mediaType: string;
  layout: LayoutEnum;
  filter: {
    gattung: Array<string>;
    adtype: Array<string>;
    mapping: Array<string>;
    kunde: Array<string>;
    produkt: Array<string>;
    kampagne: Array<string>;
    lineitem: Array<string>;
    film: Array<string>;
    werbespotname: Array<string>;
    umfeld: Array<string>;
    to_day: string;
    from_day: string;
    spalten: Array<string>;
    sender: Array<string>;
    sendung: Array<string>;
    panel: Array<string>;
  };
}
