<div class="table-config" class="table-config">
  <div class="p-top-16 p-bottom-24">
    <mat-radio-group [(ngModel)]="selectedLayout" (ngModelChange)="onLayoutChange()" class="fs-m">
      <mat-radio-button class="radio-product-selection p-right-30" [value]="layoutEnum.COMPACT">{{ 'aggregiert' | translate }}</mat-radio-button>
      <mat-radio-button class="radio-product-selection" [value]="layoutEnum.DETAIL">{{ 'detailliert' | translate }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="selectedLayout === layoutEnum.COMPACT">
    <div *ngFor="let column of compactOptions">
      <div class="column-box">
        <div class="column-name">{{ column.name }}</div>
        <div class="remove-icon" (click)="removeItem(column)"><mat-icon>close</mat-icon></div>
      </div>
    </div>
  </div>

  <div *ngIf="selectedLayout === layoutEnum.DETAIL">
    <div *ngFor="let column of detailOptions">
      <div class="column-box">
        <div class="column-name">{{ column.name }}</div>
        <div class="remove-icon" (click)="removeItem(column)"><mat-icon>close</mat-icon></div>
      </div>
    </div>
  </div>

  <app-select
    [label]="'form.label.addMore' | translate"
    [allItems]="selectedLayout === layoutEnum.COMPACT ? compactAdditionalOptions : detailAdditionalOptions"
    propertyValue="name"
    [currentValue]="selectedItems"
    [hideSelected]="true"
    (onItemSelect)="onItemSelect($event.values)"></app-select>
</div>
