import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractStoreService } from './abstract-store.service';
import { Panel } from '../../models/models';
import { shareReplay } from 'rxjs';

const REST_ENDPOINT_URI = 'stammdaten/';

@Injectable({
  providedIn: 'root'
})
export class PanelStore extends AbstractStoreService<Panel> {
  constructor(http: HttpClient) {
    super(http, REST_ENDPOINT_URI);
  }

  override getData() {
    const payload = { panelistenid: [], columns: ['panel', 'panel_klarname'] };
    this.http
      .post<Panel[]>(this.restEndpointUrl, payload, { headers: this.headers })
      .pipe(shareReplay())
      .subscribe((data) => this.dataSubject.next(data));
  }
}
