import { Pipe, PipeTransform } from '@angular/core';
import { PanelStore } from '../services/store/panel-store.service';
import { observableToPromise } from '../utils/async-utils';

@Pipe({ name: 'panelValue' })
export class PanelValuePipe implements PipeTransform {
  constructor(private panelStore: PanelStore) {}

  async transform(values: string[]): Promise<string> {
    const panels = await observableToPromise(this.panelStore.data);
    const panelNames = panels
      .filter((panel) => values.some((value) => panel.panel === value))
      .map((panel) => panel.panel_klarname)
      .join(',');
    return panelNames;
  }
}
