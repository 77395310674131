import { Injectable } from '@angular/core';
import { AbstractStoreService } from '../abstract-store.service';
import { HttpClient } from '@angular/common/http';
import { Customer, Day, Film, Product, Spot } from '../../../models/models';
import { Observable, shareReplay } from 'rxjs';
import { environment } from '../../../../environments/environment';

const REST_ENDPOINT_URI = 'sehverhalten/spots/werbekontakt/day';

@Injectable({
  providedIn: 'root'
})
export class TvDayStore extends AbstractStoreService<Day> {
  constructor(http: HttpClient) {
    super(http, REST_ENDPOINT_URI);
  }

  getByParents(customers: Customer[] | undefined, products: Product[] | undefined, films: Film[] | undefined, spots: Spot[] | undefined): Observable<Day[]> {
    let urlParameters;
    if (spots?.length) {
      urlParameters = '?werbespotname=' + encodeURIComponent(spots.map((item) => item.werbespotname).join(','));
    } else if (films?.length) {
      urlParameters = '?film=' + encodeURIComponent(films.map((item) => item.film).join(','));
    } else if (products?.length) {
      urlParameters = '?produkt=' + encodeURIComponent(products.map((item) => item.produkt).join(','));
    } else if (customers?.length) {
      urlParameters = '?kunde=' + encodeURIComponent(customers.map((item) => item.kunde).join(','));
    } else {
      return this.data;
    }
    return this.http.get<Day[]>(environment.serverUrl + REST_ENDPOINT_URI + urlParameters).pipe(shareReplay());
  }
}
