<div class="header-container row">
  <div class="col-2 left menu-container">
    <span class="logo-text"><strong>Panel</strong>ytics</span>
  </div>
  <div class="col-5 left">
    <div class="nav-element" *ngFor="let menu of menus" [routerLink]="menu?.url" (click)="setCurrentMenu(menu)" [routerLinkActive]="['active']" [hidden]="menu?.disabled">
      <span>{{ menu.title | translate }}</span>
    </div>
  </div>

  <div class="col-5 right">
    <div class="logout-link p-right-24" (click)="logout()">
      <img src="assets/icons/logout.svg" width="20" />
      <span class="p-left-6">Logout</span>
    </div>
    <img src="assets/images/rtl_adalliance_logo.svg" />
  </div>
</div>
