<div class="app-container">
  <app-loading></app-loading>
  <div class="full-width-page-container">
    <app-header></app-header>
    <div class="content-container">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>
