import { Observable, ReplaySubject, shareReplay } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export abstract class AbstractStoreService<T> {
  protected readonly restEndpointUrl: string;
  protected readonly dataSubject = new ReplaySubject<Array<T>>();
  public data: Observable<Array<T>> = this.dataSubject.asObservable();
  protected readonly headers = new HttpHeaders({
    accept: 'application/json',
    'Access-Control-Allow-Origin': '*'
  });

  protected constructor(protected http: HttpClient, protected restEndpointUri: string) {
    this.restEndpointUrl = environment.serverUrl + restEndpointUri;
    this.getData();
  }

  protected getData() {
    this.http
      .get<T[]>(this.restEndpointUrl, { headers: this.headers })
      .pipe(shareReplay())
      .subscribe((data) => this.dataSubject.next(data));
  }

  public reloadData() {
    this.getData();
  }
}
