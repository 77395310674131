import { Component, OnInit } from '@angular/core';
import { AuthenticationProviderEnum, StorageKeyEnum } from '../../shared/constants';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { LoadingService } from '../../services/loading.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit {
  constructor(private authService: AuthService, private angularFireAuth: AngularFireAuth, public loadingService: LoadingService, private router: Router) {}

  async ngOnInit() {
    const authentication = localStorage.getItem(StorageKeyEnum.AUTHENTICATION);
    if (authentication) {
      await this.router.navigate(['/contacts']);
      localStorage.removeItem(StorageKeyEnum.LOGIN_IN_PROGRESS);
      this.loadingService.loadingOff();
      return;
    }
    const loginInProgress = localStorage.getItem(StorageKeyEnum.LOGIN_IN_PROGRESS);
    if (loginInProgress && loginInProgress === 'true') {
      this.loadingService.loadingOn();
      localStorage.removeItem(StorageKeyEnum.LOGIN_IN_PROGRESS);
    }

    this.angularFireAuth.credential.subscribe((userCredential) => {
      if (userCredential) {
        //&& userCredential?.user) {
        localStorage.setItem(StorageKeyEnum.AUTHENTICATION, JSON.stringify(userCredential));
        localStorage.removeItem(StorageKeyEnum.LOGIN_IN_PROGRESS);
        this.router.navigate(['/contacts']);
        this.loadingService.loadingOff();
      } else {
        this.loadingService.loadingOff();
      }
    });
  }

  public loginWithRTLAccount() {
    this.authService.loginUser(AuthenticationProviderEnum.RTL);
  }

  public async loginWithAdAllianceAccount() {
    await this.authService.loginUser(AuthenticationProviderEnum.AD_ALLIANCE);
  }
}
