<footer class="footer-container row">
  <div class="col-12 left">
    <ul>
      <li>Panelytics {{ version }} |</li>
      <li>
        <button mat-button [routerLink]="'/privacy-policy'">{{ 'footer.privacyPolicy' | translate }}</button>
      </li>
      <li>
        <button mat-button [routerLink]="'/imprint'">{{ 'footer.imprint' | translate }}</button>
      </li>
      <li>
        <button mat-button onclick="window._sp_.loadPrivacyManagerModal(705252); return false;">{{ 'footer.privacyManager' | translate }}</button>
      </li>
      <li>
        <button mat-button class="submenu--item">
          <a href="{{ getSendFeedbackHref }}">{{ 'footer.sendFeedback' | translate }}</a>
        </button>
      </li>
    </ul>
  </div>
</footer>
