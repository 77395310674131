import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Archive, ArchiveTypeEnum, FileTypeEnum } from '../../../models/archive';
import { MatDialogRef } from '@angular/material/dialog';
import { LoadingService } from '../../../services/loading.service';
import { ArchiveService } from '../../../services/archive/archive.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-archive-select-folder-dialog',
  templateUrl: './archive-select-folder-dialog.component.html',
  styleUrls: ['./archive-select-folder-dialog.component.scss'],
  providers: [LoadingService]
})
export class ArchiveSelectFolderDialogComponent implements OnInit, OnDestroy {
  archiveTypeEnum = ArchiveTypeEnum;
  selectedArchiveType = ArchiveTypeEnum.PUBLIC;
  selectedFolder: Archive | undefined;
  onFolderSelected = new EventEmitter<{ folder: Archive | undefined; archiveType: ArchiveTypeEnum }>();
  private destroy$ = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<ArchiveSelectFolderDialogComponent>, private archiveService: ArchiveService) {}

  ngOnInit(): void {
    this.archiveService.selectedArchive$.pipe(takeUntil(this.destroy$)).subscribe((archive) => {
      this.selectedFolder = archive.type === FileTypeEnum.FOLDER ? archive : undefined;
    });
  }

  changeArchiveType(archiveType: ArchiveTypeEnum) {
    this.selectedArchiveType = archiveType;
  }

  select() {
    this.onFolderSelected.emit({ folder: this.selectedFolder, archiveType: this.selectedArchiveType });
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
