import { Injectable } from '@angular/core';
import { AbstractStoreService } from '../abstract-store.service';
import { HttpClient } from '@angular/common/http';
import { Archive } from '../../../models/archive';

const REST_ENDPOINT_URI = 'archive/public/all';

@Injectable({
  providedIn: 'root'
})
export class ArchivePublicStore extends AbstractStoreService<Archive> {
  constructor(http: HttpClient) {
    super(http, REST_ENDPOINT_URI);
  }
}
